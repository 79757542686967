/*=============================
    profile CSS start
===============================*/
.profile-cover {
    position: relative;
    width: 100%;
    height: 90px;
    background: linear-gradient(333.77deg, #ff8d2f 9.66%, #ffd3b0 101.81%);
    border-radius: 20px 20px 0px 0px;

    .profile-pic {
        position: absolute;
        bottom: -30px;
        left: 50%;
        transform: translate(-50%);
        padding: 5px;
        width: 75px;
        height: 75px;
        border-radius: 100%;
        background-color: rgba(var(--white), 1);
    }
}

.profile-name {
    margin-top: 25px;

    h5 {
        font-weight: 600;
        color: rgba(var(--dark-text), 1);
        text-align: center;
        margin-top: 30px;
    }

    h6 {
        color: rgba(var(--dark-text), 1);
        text-align: center;
        font-weight: 400;
        margin-top: 4px;
    }
}

.profile-list {
    li {
        width: 100%;
        padding-bottom: 15px;

        .profile-box {
            display: flex;
            align-items: center;
            gap: 15px;
            box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
            border-radius: 100px 38px 38px 100px;
            color: rgba(var(--dark-text), 1);
            border: 1px solid rgba(var(--black), 0.05);
            background-color: rgba(var(--box-bg), 1);

            [dir="rtl"] & {
                border-radius: 38px 100px 100px 38px;
            }

            .profile-icon {
                height: 32px;
                width: 32px;
                box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                transform: scale(1.4);
                font-size: 22px;
                border: 1px solid rgba(var(--light-text), 0.2);
                background-color: rgba(var(--box-bg), 1);

                .icon {
                    width: 16px;
                    height: 16px;
                }
            }

            .profile-box-name {
                width: calc(100% - 40px - 15px);
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 10px;
                margin-left: 6px;
                color: rgba(var(--dark-text), 1);

                [dir="rtl"] & {
                    margin-left: unset;
                    margin-right: 6px;
                }

                h6 {
                    font-weight: 500;
                    font-size: 1px;

                    .arrow {
                        margin-left: auto;
                        font-size: 14px;

                        [dir="rtl"] & {
                            margin-left: unset;
                            margin-right: auto;
                        }
                    }
                }

                i {
                    font-size: 16px;

                    [dir="rtl"] & {
                        transform: scaleX(-1);
                        margin-left: 8px;
                    }
                }
            }
        }
    }
}

.profile-img {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    height: 100px;

    .img {
        border-radius: 18px;
    }

    .camera {
        position: absolute;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(var(--white), 1);
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        font-size: 20px;
        height: 32px;
        width: 32px;
        bottom: -5px;
        right: -10px;

        [dir="rtl"] & {
            margin-left: -10px;
            margin-right: unset;
        }
    }
}
