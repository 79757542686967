/*=====================
    form CSS start
==========================*/
.auth-form {
  h2 {
    width: 90%;
    color: rgba(var(--dark-text), 1);
  }

  .form-group {
    width: 100%;
    display: block;
    .form-control {
      padding: 15px;
      line-height: 1;
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
      background-color: rgba(var(--box-bg), 1);
      border: none;
      border-radius: 6px;
      box-shadow: none;
      [dir="rtl"] & {
        text-align: right;
      }

      &:focus {
        box-shadow: none;
        border-color: transparent;
      }

      &::placeholder {
        color: rgba(var(--light-text), 1);
      }
    }
    .form-label {
      color: rgba(var(--dark-text), 1);
      white-space: nowrap;
    }

    .form-input {
      width: 100%;

      .form-control {
        background-color: rgba(var(--box-bg), 1);
        border: none;
        box-shadow: none;
        padding: 15px;
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));

        &:focus {
          box-shadow: none;
          border-color: transparent;
        }

        &::placeholder {
          color: rgba(var(--light-text), 1);
        }

        &.search {
          padding: 15px 15px 15px 42px;

          [dir="rtl"] & {
            padding: 15px 42px 15px 15px;
          }
        }
      }
    }
    .dropdown {
      display: flex;
      gap: 15px;

      .dropdown-toggle {
        background-color: rgba(var(--box-bg), 1);
        color: rgba(var(--light-text), 1);
        border-radius: 6px;
        border: none;
        display: flex;
        align-items: center;

        &::after {
          content: "\ea4e";
          font-family: remixicon;
          font-style: normal;
          font-size: 18px;
          line-height: 1;
          vertical-align: middle;
          border: none;
        }

        &:active {
          border: none;
        }
      }

      .dropdown-menu {
        &.show {
          min-width: max-content;
          background-color: rgba(var(--box-bg), 1);
          color: rgba(var(--light-text), 1);
          width: 100%;
        }

        li {
          display: block;

          .dropdown-item {
            &:active,
            &:hover {
              background-color: rgba(var(--theme-color), 1);
            }
          }
        }
      }
    }

    .form-select {
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
      line-height: 1;
      padding: 15px 30px 15px 15px;
      color: rgba(var(--dark-text), 1);
      background-color: rgba(var(--box-bg), 1);
      border: none;
    }

    .upload-image {
      position: relative;
      width: 100%;
      height: 120px;
      background-color: rgba(var(--box-bg), 0.1);
      backdrop-filter: blur(2px);
      border-radius: 6px;
      color: rgba(var(--white), 1);
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;

      .upload-file {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: rgba(var(--light-text), 1);
        opacity: 0;
        z-index: 1;
      }

      .upload-icon {
        font-size: 20px;
        color: rgba(var(--dark-text), 1);
        position: absolute;
      }

      .profile-icon {
        position: absolute;
        width: 100px;
        color: rgba(var(--dark-text), 1);
        filter: opacity(0.1);
      }
      &::after {
        content: "";
        position: relative;
        width: 100%;
        height: 100%;
        border: 1px dashed rgba(var(--light-text), 0.3);
        border-radius: 8px;
      }
      h5 {
        position: absolute;
        bottom: 30px;
        color: rgba(var(--light-text), 1);
      }
      &.profile-image {
        width: 100%;
        height: 300px;
      }
    }
  }
}

.otp-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: 40px;
  gap: 20px;

  @media (max-width: 600px) {
    gap: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
  }

  .form-input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .form-control {
      background: rgba(var(--box-bg), 1);
      backdrop-filter: blur(2px);
      border-radius: 6px;
      border: none;
      color: rgba(var(--dark-text), 1);
      text-align: center;
      height: 48px;
      padding: 15px;

      &::placeholder {
        color: rgba(var(--dark-text), 1);
      }

      &:focus {
        box-shadow: none;
        border: 1px solid rgba(var(--theme-color), 1);
      }
    }
  }

  .btn {
    margin-top: 80px;
  }
}

.profile-form {
  border-top: 1px dashed rgba(var(--dashed-line), 1);
  padding-top: 20px;
}

.order-type-3 {
  .form-check3 {
    position: relative;
    width: 100%;
    padding: 0;
    .form-check-input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      float: unset;
      height: 100%;
      margin: 0;
      cursor: pointer;
      opacity: 0;
      &:checked {
        transition: all 0.5s ease;
        ~ .form-check-label {
          font-weight: 600;
          border-color: rgba(var(--theme-color), 1);
          transition: all 0.5s ease;

          .check-box {
            background-color: rgba(var(--theme-color), 1);
            transition: all 0.5s ease;
          }
        }
      }
    }
    .form-check-label {
      width: 100%;
      padding: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
      margin: 0;
      font-weight: 400;
      font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
      display: flex;
      align-items: center;
      justify-content: center;
      gap: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
      border: 1px solid rgba(var(--black), 0.05);
      border-radius: 6px;
      .check-box {
        width: 20px;
        height: 20px;
        border: 1px solid rgba(var(--black), 0.05);
        border-radius: 100%;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 7px;
          left: 9px;
          border: 2px solid rgba(var(--white), 1);
          transform: translate(-50%, -50%) rotate(45deg);
          width: 6px;
          height: 10px;
          border-top: unset;
          border-left: unset;
        }
      }
    }
  }
}
