/*=====================
    onboarding Page CSS
==========================*/
.home {
    position: relative;

    .cooking-video {
        position: fixed;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        max-width: 767px;
        width: 100vw;
        height: 100vh;
        z-index: -1;
        object-fit: cover;
    }

    .theme-content {
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 767px;
        padding: 20px 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.89) 57.48%, rgba(0, 0, 0, 0) 100%);
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    .slogan {
        margin-top: 20px;
        padding: 12px 0;
        border-top: 1px solid rgba(225, 225, 225, 0.12);
        border-bottom: 1px solid rgba(225, 225, 225, 0.12);
        display: flex;
        align-items: center;
        justify-content: space-between;

        li {
            color: rgba(225, 225, 225, 1);
            font-size: 20px;
            font-weight: 600;
            display: flex;
            gap: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
        }
    }

    p {
        margin-top: 15px;
        font-size: 20px;
        font-weight: 600;
        color: rgba(225, 225, 225, 1);
        padding: 0;
    }

    .start-btn {
        &:active {
            background-color: rgba(var(--theme-color), 1);
            color: rgba(var(--white), 1);
        }
    }
}
