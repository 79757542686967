/*==========================
     restaurant-app CSS start
============================*/
.total-sales-background {
    width: 100%;
    padding: 15px;
    background-color: rgba(var(--theme-color), 0.1);
    border: 1px solid rgba(var(--theme-color), 0.3);
    border-radius: 6px;

    .earning-card-details {
        .icon {
            padding-right: 8px;
            border-right: 1px solid rgba(var(--theme-color), 0.3);
            [dir="rtl"] & {
                padding-right: unset;
                padding-left: 8px;
                border-right: unset;
                border-left: 1px solid rgba(var(--theme-color), 0.3);
            }
        }
        h3 {
            font-weight: 500;
            color: rgba(var(--dark-text), 1);
        }
    }
    .earning-card-details {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(var(--theme-color), 0.3);
        h3 {
            font-weight: 400;
            color: rgba(255, 255, 255, 1);
        }
        h2 {
            font-weight: 600;
            color: rgba(var(--theme-color), 1);
        }
    }
    .sale-timing-list {
        padding-top: 15px;
        display: flex;

        li {
            padding-left: calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)));
            border-left: 1px solid rgba(var(--theme-color), 0.3);
            [dir="rtl"] & {
                padding-left: unset;
                padding-right: calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)));
                border-left: unset;
                border-right: 1px solid rgba(var(--theme-color), 0.3);
            }
            &:first-child {
                padding-left: 0;
                border-left: 0;
                [dir="rtl"] & {
                    padding-left: unset;
                    padding-right: 0;
                    border-right: 0;
                    border-left: unset;
                }
            }
        }
    }
}

.coupon-list {
    li {
        width: 100%;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
        .offer-box {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px;
            gap: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
            background: rgba(var(--box-bg), 1);
            border-radius: 10px;
            cursor: pointer;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 18%;
                width: 16px;
                height: 16px;
                background-color: rgba(var(--white), 1);
                border-radius: 100%;
                transform: translateY(-50%);

                [dir="rtl"] & {
                    left: unset;
                    right: 18%;
                }
            }

            &::before {
                content: "";
                position: absolute;
                bottom: -16px;
                left: 18%;
                width: 16px;
                height: 16px;
                background-color: rgba(var(--white), 1);
                border-radius: 100%;
                transform: translateY(-50%);

                [dir="rtl"] & {
                    left: unset;
                    right: 18%;
                }
            }

            .offer-icon {
                .offer {
                    width: 40px;
                    height: 40px;
                }
            }

            .offer-content {
                width: calc(100% - 40px - 20px);
                .switch-btn {
                    z-index: 2;
                    input[type="checkbox"] {
                        position: relative;
                        width: 37px;
                        height: 22px;
                        appearance: none;
                        background: rgba(var(--light-text), 0.3);
                        outline: none;
                        border-radius: 50px;
                        cursor: pointer;

                        &:checked {
                            background: rgba(var(--theme-color), 0.3);

                            &::before {
                                transform: translateX(80%) translateY(-50%);
                                background: rgba(var(--theme-color), 1);
                            }
                        }

                        &:before {
                            content: "";
                            width: 15px;
                            height: 15px;
                            border-radius: 50%;
                            background: rgba(var(--light-text), 1);
                            position: absolute;
                            top: 50%;
                            left: 5px;
                            transform: translateY(-50%);
                            transition: 0.5s;
                        }
                    }
                }

                h5 {
                    color: rgba(var(--dark-text), 1);
                    font-size: 13px;
                }

                h6 {
                    margin-top: 5px;
                }
            }
        }
    }
}

.restaurant-banner {
    .cover-img {
        width: 100%;
        height: calc(110px + (220 - 110) * ((100vw - 320px) / (1920 - 320)));
        object-fit: cover;
        object-position: top center;
        border-radius: 10px;
    }
}

.restaurant-info-box {
    padding: 20px;
    border: 1px solid rgba(var(--black), 0.05);
    background-color: rgba(var(--white), 1);
    border-radius: 10px;
    box-shadow: 0px 3.051px 18.309px 0px rgba(var(--black), 0.04);
    .details-list {
        li {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding-bottom: 15px;
            &:nth-child(2) {
                border-bottom: 1px dashed rgba(var(--dashed-line), 1);
            }
            &:nth-child(3) {
                padding-top: 15px;
            }
            &:nth-child(7) {
                border-bottom: 1px dashed rgba(var(--dashed-line), 1);
            }
            &:nth-child(8) {
                padding-top: 15px;
            }
            &:last-child {
                padding-bottom: 0;
            }
            .heading1 {
                width: 40%;
                color: rgba(var(--dark-text), 1);
            }
            span {
                font-size: 20px;
                font-weight: 500;
                line-height: 1;
                color: rgba(var(--dark-text), 1);
            }
            .content {
                color: rgba(var(--dark-text), 1);
                padding-left: 15px;
                [dir="rtl"] & {
                    padding-right: 15px;
                    padding-left: unset;
                }
            }
        }
    }
}

.item-detail-tab {
    padding-top: 30px;
    border-top: 1px dashed rgba(var(--dashed-line), 1);

    .item-content-box {
        margin-top: 20px;
        padding: 20px;
        background-color: rgba(var(--white), 1);
        border: 1px solid rgba(var(--dashed-line), 1);
        border-radius: 10px;
        box-shadow: 0px 3px 18px 0px rgba(var(--black), 0.04);
        .item-content-head {
            padding-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid rgba(var(--dashed-line), 1);
            .box-content {
                width: 50%;
                border-right: 1px solid rgba(var(--dashed-line), 1);
                [dir="rtl"] & {
                    border-right: unset;
                    border-left: 1px solid rgba(var(--dashed-line), 1);
                }
                h5 {
                    font-weight: 600;
                    color: rgba(var(--dark-text), 1);
                }
                h6 {
                    margin-top: 10px;
                    font-weight: 500;
                    color: rgba(var(--dark-text), 1);
                }
            }
        }
        .description-part {
            padding: 15px 0;
            h5 {
                font-weight: 600;
                color: rgba(var(--dark-text), 1);
            }
            p {
                text-align: justify;
                margin-top: 5px;
                font-weight: 500;
                color: rgba(var(--dark-text), 1);
            }
        }

        .variety-part {
            h5 {
                font-weight: 600;
                color: rgba(var(--dark-text), 1);
            }
            .variety-list {
                margin-top: 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                li {
                    width: 100%;
                    padding-inline: calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)));
                    border-right: 1px solid rgba(var(--dashed-line), 1);
                    [dir="rtl"] & {
                        border-right: unset;
                        border-left: 1px solid rgba(var(--dashed-line), 1);
                    }
                    &:first-child {
                        padding-left: 0;
                        [dir="rtl"] & {
                            padding-left: unset;
                            padding-right: 0;
                        }
                    }
                    &:last-child {
                        padding-right: 0;
                        border-right: none;
                        [dir="rtl"] & {
                            padding-right: unset;
                            padding-left: 0;
                            border-right: unset;
                            border-left: none;
                        }
                    }
                }
            }
        }
    }
}

.review-list {
    margin-top: 20px;
    padding: 20px;
    background-color: rgba(var(--white), 1);
    border: 1px solid rgba(var(--dashed-line), 1);
    border-radius: 10px;
    box-shadow: 0px 3px 18px 0px rgba(var(--black), 0.04);

    .review-box {
        padding-block: 15px;
        border-bottom: 1px dashed rgba(var(--dashed-line), 1);
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
            border-bottom: none;
        }

        .review-head {
            display: flex;
            align-items: center;
            gap: 8px;

            .profile-pic {
                width: 45px;
                height: 45px;
                border-radius: 100%;
                object-fit: cover;
            }
            .content {
                width: calc(100% - 45px - 8px);
                display: flex;
                align-items: center;
                justify-content: space-between;

                h5 {
                    font-weight: 600;
                    color: rgba(var(--dark-text), 1);
                }

                h6 {
                    color: rgba(var(--light-text), 1);
                }
                .rating-star {
                    display: flex;
                    gap: 5px;
                    color: rgba(var(--dark-text), 1);

                    .star {
                        width: 12px;
                        height: 12px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 8px;
                        background-color: rgba(var(--success-color), 1);
                        border-radius: 100%;

                        i {
                            color: rgba(255, 255, 255, 1);
                        }
                    }
                }
            }
        }
        p {
            margin-top: 10px;
            font-weight: 400;
            color: rgba(var(--dark-text), 1);
        }
    }
}

.tag-part {
    .code-form-control {
        position: relative;
        padding: 15px calc(60px + (90 - 60) * ((100vw - 320px) / (1920 - 320))) 15px 15px;
    }
    .apply-btn {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        margin-top: 0px;
        font-size: 14px;
        padding: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)))
            calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 4px;
    }

    .tag-list {
        margin-top: 8px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;

        li {
            height: 25px;
            padding: 6px;
            display: inline-flex;
            align-items: center;
            gap: 5px;
            background-color: rgba(var(--white), 1);
            border: 1px solid rgba(var(--theme-color), 1);
            border-radius: 15px;
            backdrop-filter: blur(2px);
            transition: all 0.8s ease-in-out;
            h5 {
                font-size: 12px;
                font-weight: 400;
                color: rgba(var(--theme-color), 1);
            }

            .icon {
                line-height: 1;
                color: rgba(var(--theme-color), 1);
            }
        }
    }
}

.variation-part {
    padding-top: 25px;
    border-top: 1px dashed rgba(var(--dashed-line), 1);
    .variation-box {
        margin-top: 10px;
        padding: 15px;
        background-color: rgba(var(--white), 1);
        border: 1.526px solid rgba(var(--dashed-line), 1);
        border-radius: 12px;
        box-shadow: 0px 3.051px 18.309px 0px rgba(var(--black), 0.04);
        .form-check {
            margin-bottom: 0;

            .form-check-input {
                width: 20px;
                height: 20px;
                background-color: rgba(var(--white), 1);
                border: 1px solid rgba(var(--dashed-line), 1);

                &:focus {
                    box-shadow: none;
                    border-color: none;
                }
            }

            .form-check-label {
                color: rgba(var(--light-text), 1);
                font-size: 16px;
                margin-left: 10px;
                [dir="rtl"] & {
                    margin-right: 10px;
                    margin-left: unset;
                }
            }

            .form-check-input:checked {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid rgba(var(--theme-color), 1);
                &::after {
                    content: "";
                    height: 10px;
                    width: 10px;
                    background-color: rgba(var(--theme-color), 1);
                    border-radius: 100%;
                }
            }
        }
        .box-content {
            width: 50%;
            display: flex;
            justify-content: flex-start;

            .form-check-reverse {
                .form-check-input {
                    border: 1px solid rgba(var(--dashed-line), 1);
                    box-shadow: none;
                    padding-right: 0;

                    [dir="rtl"] & {
                        padding-right: unset;
                        padding-left: 0;
                    }
                }

                .form-check-input:checked {
                    background-color: rgba(var(--theme-color), 1);
                }
            }
        }
    }
}

.timing-part {
    margin-top: 20px;
    border-top: 1px dashed rgba(var(--dashed-line), 1);
}

.restaurant-order-tab {
    flex-wrap: nowrap;
    width: 100%;
    margin: 30px auto 0;
    border-radius: 6px;
    overflow: scroll;
    gap: 20px;
    display: flex;
}

.shipping-details {
    margin-top: 15px;
    padding: 15px;
    background-color: rgba(var(--white, 1));
    border: 1px solid rgba(var(--dashed-line), 1);
    border-radius: 12px;
    box-shadow: 0px 3px 18px 0px rgba(var(--black), 0.04);
    .title-content {
        font-weight: 600;
        color: rgba(var(--dark-text), 1);
    }
    .shipping-content {
        margin-top: 10px;
        padding-top: 12px;
        display: flex;
        flex-direction: column;
        border-top: 1px solid rgba(var(--dashed-line), 1);
        border-radius: 6px;
        .icon {
            width: 20px;
            height: 20px;
            filter: invert(0.3);
        }
        .icon {
            width: 20px;
            height: 20px;
            filter: invert(1) grayscale(1);
        }
        h6 {
            padding-left: 10px;
            border-left: 1px solid rgba(var(--dashed-line), 1);
            @media (max-width: 600px) {
                padding-left: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
            }
            [dir="rtl"] & {
                padding-left: unset;
                padding-right: 10px;
                border-left: unset;
                border-right: 1px solid rgba(var(--dashed-line), 1);
            }
        }
    }
}

.notify-part {
    margin-top: 25px;
    padding: 12px;
    background-color: rgba(var(--white, 1));
    border: 1px solid rgba(var(--dashed-line), 1);
    border-radius: 12px;
    box-shadow: 0px 3px 18px 0px rgba(var(--black), 0.04);
    .form-check {
        .form-check-label {
            font-weight: 600;
            font-size: 14px;
            color: rgba(var(--dark-text), 1);
        }
        .form-check-input {
            border: 1px solid rgba(var(--dashed-line), 1);
            box-shadow: none;
            padding-right: 0;

            [dir="rtl"] & {
                padding-right: unset;
                padding-left: 0;
            }
        }

        .form-check-input:checked {
            background-color: rgba(var(--theme-color), 1);
        }
    }
}

.order-item-table {
    margin-top: 25px;
    padding: 15px;
    background-color: rgba(var(--white, 1));
    border: 1px solid rgba(var(--dashed-line), 1);
    border-radius: 12px;
    box-shadow: 0px 3px 18px 0px rgba(var(--black), 0.04);

    thead {
        border-bottom: 1px solid rgba(var(--dashed-line), 1);
        tr {
            th {
                font-size: 14px;
                font-weight: 500;
                color: rgba(var(--dark-text), 1);
            }
        }
    }

    tbody {
        border-bottom: 1px solid rgba(var(--dashed-line), 1);

        tr {
            td {
                font-size: 13px;
                font-weight: 500;
                color: rgba(var(--dark-text), 1);
                &.sub-total {
                    font-size: 14px;
                }
            }
        }
    }
}
