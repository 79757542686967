/*utils scss files */
/*========================
    Animation CSS start
==========================*/
@keyframes ripple1 {
  0% {
    transform: scale(5.5);
    opacity: 0.3;
  }
  100% {
    transform: scale(8.5);
    opacity: 0;
  }
}
@keyframes ripple2 {
  0% {
    transform: scale(3.5);
  }
  100% {
    transform: scale(5.5);
  }
}
@keyframes ripple3 {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(3.5);
  }
}
/*========================
    Variable CSS start
==========================*/
/* font family */
/* color variables */
:root {
  --theme-color: 255, 141, 47;
  --light-theme-color: 255, 240, 227;
  --white: 255, 255, 255;
  --black: 0, 0, 0;
  --dark-text: 65, 68, 73;
  --light-text: 141, 143, 145;
  --success-color: 39, 125, 42;
  --error-color: 245, 58, 58;
  --accent-color: 58, 109, 229;
  --rate-color: 255, 185, 49;
  --box-bg: 245, 245, 245;
  --dashed-line: 237, 237, 237;
}

.dark {
  --white: 40, 40, 40;
  --black: 255, 255, 255;
  --dark-text: 255, 255, 255;
  --light-text: 141, 143, 145;
  --box-bg: 60, 60, 60;
  --dashed-line: 82, 82, 82;
}

.grocery-color {
  --theme-color: 59, 193, 142;
}

.pharmacy-color {
  --theme-color: 0, 136, 147;
}

/* base scss files */
/*=====================
    Reset CSS start
==========================*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input[type=number] {
  appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.custom-container {
  padding: 0 20px;
  background-color: rgba(var(--white), 1);
}

section,
.section-t-space {
  padding-top: 20px;
}

.section-b-space {
  padding-bottom: 20px;
}

.section-lg-t-space {
  padding-top: 50px;
}

.section-lg-b-space {
  padding-bottom: 50px;
}

.panel-space {
  padding-top: 80px !important;
}

.theme-color {
  color: rgba(var(--theme-color), 1) !important;
}

.dark-text {
  color: rgba(var(--dark-text), 1) !important;
}

.light-text {
  color: rgba(var(--light-text), 1) !important;
}

.error-color {
  color: rgba(var(--error-color), 1) !important;
}

.success-color {
  color: rgba(var(--success-color), 1) !important;
}

.rate-color {
  color: rgba(var(--rate-color), 1) !important;
}

.box-bg {
  background-color: rgba(var(--box-bg), 1) !important;
}

.accent-color {
  color: rgba(var(--accent-color), 1) !important;
}

[dir=rtl] .swiper {
  direction: ltr;
}

/*=====================
    Typography CSS start
==========================*/
* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Metropolis", sans-serif;
  background-color: rgba(var(--white), 1);
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  overflow-y: auto;
}
body::-webkit-scrollbar {
  scrollbar-width: none;
  scrollbar-color: #000000;
  width: 1px;
}
body::-webkit-scrollbar-track {
  background: #ffffff;
}
body::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 0px;
  border: 3px solid #ffffff;
}

h1 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0;
}

h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

h3 {
  font-size: 16px;
  margin-bottom: 0;
}

h4 {
  font-size: 15px;
  margin-bottom: 0;
}

h5 {
  font-size: 14px;
  line-height: 1;
  margin-bottom: 0;
}

h6 {
  font-size: calc(12px + 1 * (100vw - 320px) / 1600);
  margin-bottom: 0;
}

p {
  font-size: 13px;
  margin-bottom: 0;
}

a {
  text-decoration: none;
}

ul {
  padding: 0;
  margin-bottom: 0;
}

li {
  list-style: none;
  display: inline-block;
  font-size: 14px;
}

/* components scss files*/
/*=====================
    accordion CSS start
==========================*/
.food-accordion .accordion-item {
  background: transparent;
  border: none;
  border-bottom: 1px solid rgba(var(--black), 0.05);
  z-index: -1;
}
.food-accordion .accordion-item .accordion-header {
  font-size: 14px;
  position: relative;
  z-index: 1;
}
.food-accordion .accordion-item .accordion-header::after {
  content: "";
  position: absolute;
  border-bottom: 1px dashed rgba(var(--dashed-line), 1);
  width: 60px;
}
.food-accordion .accordion-item .accordion-header .accordion-button {
  padding: 16px 0;
  font-size: 14px;
  font-weight: 400;
  color: rgba(var(--dark-text), 1);
  background-color: rgba(var(--white), 1);
}
.food-accordion .accordion-item .accordion-header .accordion-button::after {
  content: "\ea4e";
  font-family: remixicon !important;
  width: auto;
  height: auto;
  background: none;
  font-size: 24px;
  line-height: 1;
  background-image: none;
  transform: none;
  margin-left: auto;
  margin-right: unset;
}
[dir=rtl] .food-accordion .accordion-item .accordion-header .accordion-button::after {
  margin-left: unset;
  margin-right: auto;
}
.food-accordion .accordion-item .accordion-header .accordion-button:focus {
  box-shadow: none;
}
.food-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  background: none;
  color: rgba(var(--dark-text), 1);
  font-weight: 400;
  font-size: 14px;
}
.food-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  content: "\ea78";
  font-family: remixicon !important;
  width: auto;
  height: auto;
  background: none;
  font-size: 24px;
  line-height: 1;
  background-image: none;
  transform: none;
  margin-left: auto;
}
[dir=rtl] .food-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  margin-left: unset;
  margin-right: auto;
}
.food-accordion .accordion-item .accordion-body {
  padding: 20px 0 20px 0;
}

.option-accordion .accordion-item {
  background-color: rgba(var(--white), 1);
  border: 1px solid rgba(var(--black), 0.05);
  box-shadow: 0px 2px 12px rgba(var(--black), 0.06);
  border-radius: 8px;
}
.option-accordion .accordion-item .accordion-header {
  position: relative;
  font-size: 14px;
  background-color: rgba(var(--white), 1);
  border: 1px solid rgba(var(--black), 0.05);
  box-shadow: 0px 2px 12px rgba(var(--black), 0.06);
  border-radius: 8px;
}
.option-accordion .accordion-item .accordion-header .accordion-button {
  padding: 15px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(var(--dark-text), 1);
  background-color: rgba(var(--white), 1);
}
.option-accordion .accordion-item .accordion-header .accordion-button::after {
  transform: none;
  margin-left: auto;
  font-weight: normal;
  content: "\ea4e";
  font-family: remixicon !important;
  width: auto;
  height: auto;
  background: none;
  font-size: 24px;
  line-height: 1;
}
[dir=rtl] .option-accordion .accordion-item .accordion-header .accordion-button::after {
  margin-left: unset;
  margin-right: auto;
}
.option-accordion .accordion-item .accordion-header .accordion-button:focus {
  box-shadow: none;
}
.option-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  background: none;
  color: rgba(var(--dark-text), 1);
  font-weight: 600;
  font-size: 14px;
}
.option-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  content: "\ea78";
  font-family: remixicon !important;
  width: auto;
  height: auto;
  background: none;
  font-size: 24px;
  line-height: 1;
  background-image: none;
  transform: none;
}
.option-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed):focus {
  box-shadow: none;
}
.option-accordion .accordion-item .accordion-body {
  padding: 0 15px 12px;
}
.option-accordion .accordion-item .accordion-body .form-check {
  text-align: left;
  padding: 12px 0;
  margin: 0;
  border-bottom: 1px solid rgba(var(--dashed-line), 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(var(--white), 1);
}
.option-accordion .accordion-item .accordion-body .form-check:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.option-accordion .accordion-item .accordion-body .form-check .form-check-label {
  color: rgba(var(--dark-text), 1);
  font-size: 14px;
}
.option-accordion .accordion-item .accordion-body .form-check .form-check-input {
  box-shadow: none;
  float: right;
  margin: 0;
}
[dir=rtl] .option-accordion .accordion-item .accordion-body .form-check .form-check-input {
  float: left;
}
.option-accordion .accordion-item .accordion-body .form-check .form-check-input:checked {
  background-color: rgba(var(--theme-color), 1);
  border-color: rgba(var(--theme-color), 1);
}
.option-accordion .accordion-item .currency {
  padding: 0 15px 12px 12px;
}
[dir=rtl] .option-accordion .accordion-item .currency {
  padding: 0 12px 12px 15px;
}
.option-accordion .accordion-item .currency .currency-listing li {
  display: block;
  width: 100%;
  padding: 12px 0;
  font-weight: 400;
  border-bottom: 1px solid rgba(var(--dashed-line), 1);
}
.option-accordion .accordion-item .currency .currency-listing li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

/*=====================
    Button CSS start
==========================*/
.btn {
  margin-top: 30px;
  padding: calc(8px + 4 * (100vw - 320px) / 1600);
}
.btn-inline {
  padding-inline: 15px;
}
.btn:active {
  background-color: rgba(var(--theme-color), 1);
  color: rgba(var(--white), 1);
  border: 1px solid rgba(var(--theme-color), 1);
}

.theme-btn {
  background-color: rgba(var(--theme-color), 1);
  color: #ffffff;
  font-weight: 500;
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
  border-radius: 6px;
}
.theme-btn:hover {
  background-color: rgba(var(--theme-color), 1);
  color: #ffffff;
}
.theme-btn:active {
  background-color: rgba(var(--theme-color), 1);
  color: rgba(var(--white), 1);
  border: 1px solid rgba(var(--theme-color), 1);
}

.gray-btn {
  background-color: rgba(var(--box-bg), 1);
  color: rgba(var(--light-text), 1);
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.gray-btn:active {
  border: none;
}
.gray-btn:hover {
  background-color: rgba(var(--box-bg), 1);
  color: rgba(var(--light-text), 1);
}

.btn-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: rgba(var(--light-text), 1);
}
.btn-link:hover {
  color: rgba(var(--light-text), 1);
}

/*=========================
    Category CSS start
==========================*/
.categories .food-categories {
  position: relative;
  width: 80%;
  height: 80%;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  margin: 0 auto;
  border-radius: 8px;
  z-index: 1;
  background: linear-gradient(360deg, #f5f5f5 53.51%, rgba(245, 245, 245, 0.1098039216) 100%);
}
.categories .food-categories.restaurant-food-categories {
  background: rgba(var(--white), 1) !important;
}
@media (max-width: 420px) {
  .categories .food-categories {
    height: 100%;
    width: 100%;
  }
}
.categories .food-categories.food {
  background: rgba(var(--white), 1);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}
.categories .food-categories::after {
  content: "";
  position: absolute;
  width: 98%;
  height: 100%;
  border-radius: 9px;
  left: 50%;
  bottom: 2px;
  z-index: -1;
  transform: translateX(-50%);
}
.categories .food-categories .categories-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 50px;
  object-fit: contain;
}
@media (max-width: 420px) {
  .categories .food-categories .categories-img {
    width: 100%;
    height: 40px;
  }
}
.categories h6 {
  color: rgba(var(--dark-text), 1);
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.msger {
  margin-top: 105px;
}

.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}
.msg:last-of-type {
  margin: 0;
}

.msg-img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}
[dir=rtl] .msg-img {
  margin-left: 10px;
  margin-right: unset;
}

.msg-bubble {
  max-width: 450px;
  padding: 8px;
  border-radius: 15px;
  background: var(--left-msg-bg);
  font-size: 14px;
}

.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}
[dir=rtl] .msg-info-name {
  margin-left: 10px;
  margin-right: unset;
}

.msg-info-time {
  font-size: 0.85em;
}

.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
  background: rgba(var(--box-bg), 1);
  border-radius: 6px 6px 6px 0;
  color: rgba(var(--dark-text), 1);
  width: calc(100% - 50px - 10px - 40px);
  margin-right: 0;
}
[dir=rtl] .left-msg .msg-bubble {
  margin-left: 0;
  margin-right: unset;
}

.right-msg {
  flex-direction: row-reverse;
}
.right-msg .msg-bubble {
  background: rgba(var(--theme-color), 1);
  border-radius: 6px 6px 0 6px;
  color: rgba(var(--white), 1);
}
.right-msg .msg-bubble .msg-text {
  color: #ffffff;
}
.right-msg .msg-img {
  margin-left: 10px;
}
[dir=rtl] .right-msg .msg-img {
  margin-left: unset;
  margin-right: 10px;
}
.right-msg .msg-img .icon {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.msger-inputarea {
  display: flex;
  padding: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(var(--white), 1);
}
[dir=rtl] .msger-inputarea {
  left: unset;
  right: 0;
}
.msger-inputarea * {
  padding: 15px;
  border: none;
  border-radius: 3px;
  font-size: 1em;
}

.msger-input {
  background-color: rgba(var(--box-bg), 1);
  backdrop-filter: blur(2px);
  padding: 14px;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  color: rgba(var(--dark-text), 1);
}
.msger-input::placeholder {
  color: rgba(var(--light-text), 1);
}
.msger-input:focus {
  outline: none;
}

.msger-send-btn {
  margin-left: 8px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  transition: background 0.23s;
  background: rgba(var(--theme-color), 1);
  backdrop-filter: blur(2px);
  border-radius: 6px;
}
[dir=rtl] .msger-send-btn {
  margin-left: unset;
  margin-right: 8px;
}

/*=====================
    form CSS start
==========================*/
.auth-form h2 {
  width: 90%;
  color: rgba(var(--dark-text), 1);
}
.auth-form .form-group {
  width: 100%;
  display: block;
}
.auth-form .form-group .form-control {
  padding: 15px;
  line-height: 1;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  background-color: rgba(var(--box-bg), 1);
  border: none;
  border-radius: 6px;
  box-shadow: none;
}
[dir=rtl] .auth-form .form-group .form-control {
  text-align: right;
}
.auth-form .form-group .form-control:focus {
  box-shadow: none;
  border-color: transparent;
}
.auth-form .form-group .form-control::placeholder {
  color: rgba(var(--light-text), 1);
}
.auth-form .form-group .form-label {
  color: rgba(var(--dark-text), 1);
  white-space: nowrap;
}
.auth-form .form-group .form-input {
  width: 100%;
}
.auth-form .form-group .form-input .form-control {
  background-color: rgba(var(--box-bg), 1);
  border: none;
  box-shadow: none;
  padding: 15px;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}
.auth-form .form-group .form-input .form-control:focus {
  box-shadow: none;
  border-color: transparent;
}
.auth-form .form-group .form-input .form-control::placeholder {
  color: rgba(var(--light-text), 1);
}
.auth-form .form-group .form-input .form-control.search {
  padding: 15px 15px 15px 42px;
}
[dir=rtl] .auth-form .form-group .form-input .form-control.search {
  padding: 15px 42px 15px 15px;
}
.auth-form .form-group .dropdown {
  display: flex;
  gap: 15px;
}
.auth-form .form-group .dropdown .dropdown-toggle {
  background-color: rgba(var(--box-bg), 1);
  color: rgba(var(--light-text), 1);
  border-radius: 6px;
  border: none;
  display: flex;
  align-items: center;
}
.auth-form .form-group .dropdown .dropdown-toggle::after {
  content: "\ea4e";
  font-family: remixicon;
  font-style: normal;
  font-size: 18px;
  line-height: 1;
  vertical-align: middle;
  border: none;
}
.auth-form .form-group .dropdown .dropdown-toggle:active {
  border: none;
}
.auth-form .form-group .dropdown .dropdown-menu.show {
  min-width: max-content;
  background-color: rgba(var(--box-bg), 1);
  color: rgba(var(--light-text), 1);
  width: 100%;
}
.auth-form .form-group .dropdown .dropdown-menu li {
  display: block;
}
.auth-form .form-group .dropdown .dropdown-menu li .dropdown-item:active, .auth-form .form-group .dropdown .dropdown-menu li .dropdown-item:hover {
  background-color: rgba(var(--theme-color), 1);
}
.auth-form .form-group .form-select {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  line-height: 1;
  padding: 15px 30px 15px 15px;
  color: rgba(var(--dark-text), 1);
  background-color: rgba(var(--box-bg), 1);
  border: none;
}
.auth-form .form-group .upload-image {
  position: relative;
  width: 100%;
  height: 120px;
  background-color: rgba(var(--box-bg), 0.1);
  backdrop-filter: blur(2px);
  border-radius: 6px;
  color: rgba(var(--white), 1);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
}
.auth-form .form-group .upload-image .upload-file {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: rgba(var(--light-text), 1);
  opacity: 0;
  z-index: 1;
}
.auth-form .form-group .upload-image .upload-icon {
  font-size: 20px;
  color: rgba(var(--dark-text), 1);
  position: absolute;
}
.auth-form .form-group .upload-image .profile-icon {
  position: absolute;
  width: 100px;
  color: rgba(var(--dark-text), 1);
  filter: opacity(0.1);
}
.auth-form .form-group .upload-image::after {
  content: "";
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px dashed rgba(var(--light-text), 0.3);
  border-radius: 8px;
}
.auth-form .form-group .upload-image h5 {
  position: absolute;
  bottom: 30px;
  color: rgba(var(--light-text), 1);
}
.auth-form .form-group .upload-image.profile-image {
  width: 100%;
  height: 300px;
}

.otp-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: 40px;
  gap: 20px;
}
@media (max-width: 600px) {
  .otp-form {
    gap: calc(10px + 10 * (100vw - 320px) / 1600);
  }
}
.otp-form .form-input::-webkit-outer-spin-button, .otp-form .form-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.otp-form .form-input .form-control {
  background: rgba(var(--box-bg), 1);
  backdrop-filter: blur(2px);
  border-radius: 6px;
  border: none;
  color: rgba(var(--dark-text), 1);
  text-align: center;
  height: 48px;
  padding: 15px;
}
.otp-form .form-input .form-control::placeholder {
  color: rgba(var(--dark-text), 1);
}
.otp-form .form-input .form-control:focus {
  box-shadow: none;
  border: 1px solid rgba(var(--theme-color), 1);
}
.otp-form .btn {
  margin-top: 80px;
}

.profile-form {
  border-top: 1px dashed rgba(var(--dashed-line), 1);
  padding-top: 20px;
}

.order-type-3 .form-check3 {
  position: relative;
  width: 100%;
  padding: 0;
}
.order-type-3 .form-check3 .form-check-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  float: unset;
  height: 100%;
  margin: 0;
  cursor: pointer;
  opacity: 0;
}
.order-type-3 .form-check3 .form-check-input:checked {
  transition: all 0.5s ease;
}
.order-type-3 .form-check3 .form-check-input:checked ~ .form-check-label {
  font-weight: 600;
  border-color: rgba(var(--theme-color), 1);
  transition: all 0.5s ease;
}
.order-type-3 .form-check3 .form-check-input:checked ~ .form-check-label .check-box {
  background-color: rgba(var(--theme-color), 1);
  transition: all 0.5s ease;
}
.order-type-3 .form-check3 .form-check-label {
  width: 100%;
  padding: calc(10px + 5 * (100vw - 320px) / 1600);
  margin: 0;
  font-weight: 400;
  font-size: calc(13px + 1 * (100vw - 320px) / 1600);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: calc(5px + 5 * (100vw - 320px) / 1600);
  border: 1px solid rgba(var(--black), 0.05);
  border-radius: 6px;
}
.order-type-3 .form-check3 .form-check-label .check-box {
  width: 20px;
  height: 20px;
  border: 1px solid rgba(var(--black), 0.05);
  border-radius: 100%;
  position: relative;
}
.order-type-3 .form-check3 .form-check-label .check-box::after {
  content: "";
  position: absolute;
  top: 7px;
  left: 9px;
  border: 2px solid rgba(var(--white), 1);
  transform: translate(-50%, -50%) rotate(45deg);
  width: 6px;
  height: 10px;
  border-top: unset;
  border-left: unset;
}

/*=====================
    offcanvas CSS start
==========================*/
.search-filter .modal-title {
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: rgba(var(--white), 1);
}
.search-filter .modal-title h3 {
  margin-left: auto;
  margin-right: auto;
  color: rgba(var(--dark-text), 1);
}
.search-filter .modal-title .btn-close {
  position: absolute;
  right: 20px;
  box-shadow: none;
}
.search-filter .search-categories li {
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid rgba(var(--black), 0.1);
}
.search-filter .search-categories li .payment-detail {
  display: flex;
  gap: 10px;
}
.search-filter .search-categories li .payment-detail .add-img {
  padding: 7px;
  border-radius: 100%;
  background: rgba(var(--theme-color), 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-filter .search-categories li .payment-detail .form-check-label {
  color: rgba(var(--dark-text), 1);
}
.search-filter .search-categories li .payment-detail .radio-btn {
  padding: 0;
  width: calc(100% - 32px - 10px);
  margin: 0;
  min-height: unset;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search-filter .search-categories li .payment-detail .radio-btn .form-check-input {
  float: unset;
  margin: 0;
}
.search-filter .search-categories li .payment-detail .radio-btn .form-check-input {
  box-shadow: none;
  font-size: 20px;
}
.search-filter .search-categories li .payment-detail .radio-btn .form-check-input:checked[type=radio] {
  background-color: rgba(var(--theme-color), 1) !important;
  border-color: rgba(var(--theme-color), 1) !important;
  background-image: url(../images/svg/check.svg);
  margin-left: unset;
}
.search-filter .amount-range {
  margin: 20px 0;
  border-bottom: 1px solid rgba(var(--black), 0.1);
  /* Input Thumb */
  /* Input Track */
}
.search-filter .amount-range .range {
  display: flex;
  justify-content: space-between;
}
.search-filter .amount-range .range input,
.search-filter .amount-range .range output {
  display: inline-block;
  vertical-align: middle;
}
.search-filter .amount-range .range .form-label {
  color: rgba(var(--dark-text), 1);
  font-size: 16px;
  font-weight: 600;
}
.search-filter .amount-range .range output {
  width: 41px;
  height: 25px;
  display: flex;
  padding: 5px 8px;
  color: rgba(var(--light-text), 1);
  background: rgba(var(--white), 1);
  border: 1px solid rgba(var(--black), 0.05);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.search-filter .amount-range input[type=number] {
  width: 40px;
  padding: 4px 5px;
  border: 1px solid #bbb;
  border-radius: 3px;
}
.search-filter .amount-range input[type=range] {
  -webkit-appearance: none;
  margin-right: 15px;
  width: 100%;
  height: 7px;
  background: rgba(var(--box-bg), 1);
  border-radius: 5px;
  background-image: linear-gradient(rgba(var(--theme-color), 1), rgba(var(--theme-color), 1));
  background-size: 100%;
  background-repeat: no-repeat;
  margin-bottom: 20px;
}
.search-filter .amount-range input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: rgba(var(--theme-color), 1);
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: 0.3s ease-in-out;
}
.search-filter .amount-range input[type=range]::-webkit-slider-thumb:hover {
  background-image: rgba(var(--theme-color), 1);
}
.search-filter .amount-range input[type=range]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
.search-filter .order-type {
  display: flex;
  gap: 15px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(var(--white), 0.1);
}
.search-filter .order-type .search-form {
  width: 100%;
  border-radius: 6px;
  background-color: rgba(var(--box-bg), 1);
  padding: 15px;
}
.search-filter .order-type .search-form.search-form-check:checked {
  background-color: rgba(var(--white), 1);
  border: 1px solid rgba(var(--theme-color), 1);
}
.search-filter .order-type .search-form .form-check {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}
.search-filter .order-type .search-form .form-check .form-check-label {
  font-weight: 400;
  font-size: calc(12px + 2 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 1);
  margin-right: auto;
}
[dir=rtl] .search-filter .order-type .search-form .form-check .form-check-label {
  margin-right: unset;
  margin-left: auto;
}
.search-filter .order-type .search-form .form-check .form-check-input:checked {
  background-color: rgba(var(--theme-color), 1);
  border-color: rgba(var(--theme-color), 1);
}
.search-filter .order-type .search-form .form-check .form-check-input:focus {
  box-shadow: none;
}
.search-filter .order-type .search-form .form-check .form-check-input:checked[type=radio] {
  background-image: url(../images/svg/check.svg);
}
.search-filter .order-type .search-form .form-check .form-check-input[type=radio] {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin: 0;
}
.search-filter .footer-modal {
  position: fixed;
  bottom: 0;
  padding: 12px 20px;
  width: 100%;
  background-color: rgba(var(--white), 1);
  box-shadow: 0px 5px 24px rgba(0, 0, 0, 0.2);
}

.filter-modal .filter-header {
  display: flex;
  width: 100%;
}
.filter-modal .filter-header .title {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  color: rgba(var(--dark-text), 1);
}
.filter-modal .btn-close {
  position: absolute;
  top: 20px;
  color: rgba(var(--dark-text), 1);
  box-shadow: none;
}
.filter-modal .filter-body .filter-box h3 {
  font-weight: 600;
  font-size: 16px;
}
.filter-modal .filter-body .filter-box h2 {
  color: rgba(var(--theme-color), 1);
}
.filter-modal .filter-body .filter-list {
  border-bottom: 1.5px dashed rgba(var(--dashed-line), 1);
}
.filter-modal .filter-body .filter-list li {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}
.filter-modal .filter-body .filter-list li .product-size {
  color: rgba(var(--dark-text), 1);
}
.filter-modal .filter-body .filter-list li .product-price {
  margin-left: auto;
  display: flex;
}
[dir=rtl] .filter-modal .filter-body .filter-list li .product-price {
  margin-left: unset;
  margin-right: auto;
}
.filter-modal .filter-body .filter-list li .product-price .form-check-label {
  margin-right: 30px;
  font-weight: 600;
  color: rgba(var(--dark-text), 1);
}
[dir=rtl] .filter-modal .filter-body .filter-list li .product-price .form-check-label {
  margin-right: unset;
  margin-left: 30px;
}
.filter-modal .filter-body .filter-list li .product-price .form-check-input {
  border: 1px solid rgba(var(--light-text), 1);
  box-shadow: none;
  padding-right: 0;
}
[dir=rtl] .filter-modal .filter-body .filter-list li .product-price .form-check-input {
  padding-right: unset;
  padding-left: 0;
}
.filter-modal .filter-body .filter-list li .product-price .form-check-input:checked {
  background-color: rgba(var(--theme-color), 1);
  border: none;
}
.filter-modal .filter-footer {
  margin-top: 75px;
  padding-bottom: 20px;
}

.product-detail-popup {
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.14);
  border-radius: 15px 15px 0px 0px;
  padding: 20px;
}
.product-detail-popup.show {
  height: auto;
  background-color: rgba(var(--white), 1);
}
.product-detail-popup .product-img {
  width: 100%;
  border-radius: 16px;
}
.product-detail-popup .product-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px dashed rgba(var(--black), 0.1);
}
.product-detail-popup .product-content .product-name {
  font-weight: 600;
  font-size: 14px;
  margin: 5px 0;
  color: rgba(var(--dark-text), 1);
}
.product-detail-popup .product-content .rating-stars {
  display: flex;
}
.product-detail-popup .product-content .rating-stars .stars {
  color: rgba(var(--rate-color), 1);
}
.product-detail-popup .product-content .product-price {
  font-size: 14px;
  font-weight: 600;
  color: rgba(var(--light-text), 1);
}
.product-detail-popup .product-content .product-price span {
  color: rgba(var(--theme-color), 1);
  font-size: 16px;
  font-weight: 600;
}
.product-detail-popup .product-content .plus-minus {
  border-radius: 6px;
  border: 1px solid rgba(var(--theme-color), 1);
  padding: 6px 17px;
  display: inline-flex;
  align-items: center;
  background-color: rgba(var(--white), 1);
  text-align: center;
}
.product-detail-popup .product-content .plus-minus input {
  background-color: rgba(var(--white), 1);
  color: rgba(var(--theme-color), 1);
  border: none;
  font-size: 14px;
  outline: none;
  width: 35px;
  text-align: center;
}
.product-detail-popup .product-content .plus-minus i {
  color: rgba(var(--theme-color), 1);
}
.product-detail-popup p {
  padding: 10px 0;
  line-height: 17px;
  margin: 0;
}
.product-detail-popup .ingredient-list {
  padding-inline-start: 20px;
  width: 100%;
}
.product-detail-popup .ingredient-list li {
  color: rgba(var(--light-text), 1);
  font-size: 13px;
  display: list-item;
  list-style-type: disc;
}

.cart-popup {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  max-width: 600px;
  background: linear-gradient(180deg, #414449 1.31%, #1f1f1f 101.06%);
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.14);
  border-radius: 20px 20px 0px 0px;
  height: auto;
  padding: 20px;
  display: none;
  display: flex;
  align-items: center;
}
.cart-popup .price-items h3 {
  font-weight: 700;
  font-size: 16px;
  color: rgba(var(--theme-color), 1);
}
.cart-popup .price-items h6 {
  color: rgb(255, 255, 255);
  margin-top: 6px;
}
.cart-popup .cart-btn {
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 7px 10px;
  margin-left: auto;
}
[dir=rtl] .cart-popup .cart-btn {
  margin-left: unset;
  margin-right: auto;
}

.pay-popup {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  max-width: 600px;
  background: linear-gradient(180deg, #414449 1.31%, #1f1f1f 101.06%);
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.14);
  border-radius: 20px 20px 0px 0px;
  height: auto;
  display: flex;
  align-items: center;
  padding: 20px;
}
.pay-popup .price-items h3 {
  font-weight: 700;
  font-size: 16px;
  color: rgba(var(--theme-color), 1);
}
.pay-popup .price-items h6 {
  color: rgb(255, 255, 255);
  margin-top: 6px;
}
.pay-popup .pay-btn {
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 7px 10px;
  margin-left: auto;
}
[dir=rtl] .pay-popup .pay-btn {
  margin-left: unset;
  margin-right: auto;
}

.tracking-product {
  padding: 20px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.6);
  border-radius: 20px 20px 0px 0px;
  background-color: rgba(var(--white), 1);
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 50%;
  max-width: 600px;
  transform: translateX(-50%);
}
.tracking-product .tracking-head {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-bottom: 15px;
  border-bottom: 1px dashed rgba(var(--black), 0.22);
}
.tracking-product .tracking-head .profile-pic {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  object-fit: cover;
}
.tracking-product .tracking-head h5 {
  font-weight: 600;
  color: rgba(var(--dark-text), 1);
}
.tracking-product .tracking-head h6 {
  color: rgba(var(--light-text), 1);
}
.tracking-product .tracking-head .content-option {
  margin-left: auto;
  display: flex;
  gap: 10px;
}
[dir=rtl] .tracking-product .tracking-head .content-option {
  margin-left: unset;
  margin-right: auto;
}
.tracking-product .tracking-head .content-option .message-icon {
  background-color: rgb(47, 168, 255);
  padding: 6px;
  border-radius: 100%;
}
.tracking-product .tracking-head .content-option .call-icon {
  background-color: rgba(var(--success-color), 1);
  padding: 6px;
  border-radius: 100%;
}
.tracking-product .tracking-head .content-option .document-icon {
  background-color: rgba(var(--theme-color), 1);
  padding: 6px;
  border-radius: 100%;
}
.tracking-product .tracking-body {
  padding: 15px 0;
  border-bottom: 1px dashed rgba(var(--black), 0.22);
}
.tracking-product .tracking-body .tracking-place li {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding-bottom: 25px;
}
.tracking-product .tracking-body .tracking-place li:last-child::after {
  display: none;
}
.tracking-product .tracking-body .tracking-place li::after {
  content: "";
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 25px;
  border: 1px dashed rgba(var(--light-text), 1);
  transform: rotate(90deg);
}
[dir=rtl] .tracking-product .tracking-body .tracking-place li::after {
  left: unset;
  right: 0;
}
.tracking-product .tracking-body .tracking-place li span {
  position: relative;
  width: 25px;
  height: 25px;
  background-color: rgba(var(--box-bg), 1);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tracking-product .tracking-body .tracking-place li span::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: rgba(var(--light-text), 1);
  border-radius: 100%;
}
.tracking-product .tracking-body .tracking-place li.active li::after {
  border: rgba(var(--theme-color), 1);
}
.tracking-product .tracking-body .tracking-place li.active::after {
  border-color: rgba(var(--theme-color), 1);
}
.tracking-product .tracking-body .tracking-place li.active span {
  background-color: rgba(var(--theme-color), 0.15);
}
.tracking-product .tracking-body .tracking-place li.active span::after {
  background-color: rgba(var(--theme-color), 1);
}

.delivery-modal .modal-content {
  padding: 20px;
}
.delivery-modal .modal-content h5 {
  line-height: 1.2;
}

.confirm-title .for-dark {
  display: none;
}

.feedback-modal .modal-content {
  padding: 20px;
}
.feedback-modal .modal-content .modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(var(--white), 1);
}
.feedback-modal .modal-content .modal-title h3 {
  color: rgba(var(--black), 1);
}
.feedback-modal .modal-content .rating {
  display: flex;
  justify-content: center;
  font-size: 40px;
  gap: 5px;
  margin-top: 30px;
}
.feedback-modal .modal-content .rating .star {
  color: rgba(var(--theme-color), 1);
}
.feedback-modal .modal-content .feedback-box {
  margin-top: 30px;
  background-color: rgba(var(--box-bg), 1);
  border: none;
  box-shadow: none;
}

.done-modal .modal-content {
  padding: 20px;
}
.done-modal .modal-content h5 {
  line-height: 1.2;
}

.location-modal .modal-title {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px;
  color: rgba(var(--dark-text), 1);
  background-color: rgba(var(--white), 1);
}
.location-modal .modal-title i {
  font-size: 20px;
}
.location-modal .modal-title .btn-close {
  margin-left: auto;
}
.location-modal .current-location {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid rgba(var(--dashed-line), 1);
}
.location-modal .current-location .current-address {
  display: flex;
  gap: 10px;
}
.location-modal .current-location .current-address .focus {
  font-weight: 600;
  color: rgba(var(--theme-color), 1);
}
.location-modal .current-location .current-address h4 {
  color: rgba(var(--theme-color), 1);
  font-weight: 600;
}
.location-modal .current-location .current-address h6 {
  width: 80%;
  margin-top: 4px;
  color: rgba(var(--light-text), 1);
}
.location-modal .current-location .arrow {
  font-size: 20px;
  color: rgba(var(--dark-text), 1);
}
.location-modal .recent-location {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid rgba(var(--dashed-line), 1) 10;
}
.location-modal .recent-location .loation-icon {
  background-color: rgba(var(--theme-color), 1);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: rgba(var(--white), 1);
}
.location-modal .recent-location .loation-icon i {
  color: rgb(255, 255, 255);
}
.location-modal .recent-location .recant-address {
  margin-top: 4px;
  width: 80%;
}
.location-modal .recent-location .recant-address h3 {
  color: rgba(var(--dark-text), 1);
  font-weight: 500;
}
.location-modal .recent-location .recant-address h6 {
  margin-top: 4px;
  color: rgba(var(--light-text), 1);
}

.offcanvas.addtohome-popup {
  height: auto;
}
.offcanvas.addtohome-popup .btn-close {
  position: absolute;
  right: 20px;
  top: 20px;
}
[dir=rtl] .offcanvas.addtohome-popup .btn-close {
  left: 20px;
  right: unset;
  text-align: right;
}
.offcanvas.addtohome-popup .offcanvas-body {
  text-align: right;
  background-color: rgba(var(--white));
  padding: 15px;
}
[dir=rtl] .offcanvas.addtohome-popup .offcanvas-body {
  text-align: left;
}
.offcanvas.addtohome-popup .offcanvas-body .app-info {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  text-align: left;
}
.offcanvas.addtohome-popup .offcanvas-body .app-info .content {
  padding-left: 15px;
}
[dir=rtl] .offcanvas.addtohome-popup .offcanvas-body .app-info .content {
  padding-left: 0;
  padding-right: 15px;
}
.offcanvas.addtohome-popup .offcanvas-body .app-info .content h3 {
  font-weight: 600;
  color: rgba(var(--dark-text), 1);
}
.offcanvas.addtohome-popup .offcanvas-body .app-info .content a {
  color: rgba(var(--light-text), 1);
}
.offcanvas.addtohome-popup .offcanvas-body .home-screen-btn {
  display: inline-block;
}

.sidebar-offcanvas {
  width: 280px !important;
  background-color: rgba(var(--white), 1);
}
.sidebar-offcanvas.show {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  height: 100%;
  visibility: visible;
}
.sidebar-offcanvas .offcanvas-header {
  display: flex;
  justify-content: unset;
}
.sidebar-offcanvas .offcanvas-header h3 {
  color: rgba(var(--dark-text), 1);
  margin-left: 10px;
}
[dir=rtl] .sidebar-offcanvas .offcanvas-header h3 {
  margin-left: 0;
  margin-right: 10px;
}
.sidebar-offcanvas .offcanvas-header img {
  width: 40px;
  border-radius: 100%;
}
.sidebar-offcanvas .offcanvas-header .btn-close {
  background-color: rgba(var(--white), 0.4);
  margin-left: auto;
  margin-right: 0px;
  font-size: 10px;
  box-shadow: none;
}
[dir=rtl] .sidebar-offcanvas .offcanvas-header .btn-close {
  margin-left: 0;
  margin-right: auto;
}
.sidebar-offcanvas .sidebar-content .link-section li {
  display: block;
  padding: 15px;
  border-bottom: 1px solid rgba(var(--dark-text), 0.1);
}
.sidebar-offcanvas .sidebar-content .link-section li:last-child() {
  display: none;
}
.sidebar-offcanvas .sidebar-content .link-section li .pages {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar-offcanvas .sidebar-content .link-section li .pages .switch-btn input[type=checkbox] {
  position: relative;
  width: 37px;
  height: 22px;
  appearance: none;
  background: rgba(var(--box-bg), 1);
  outline: none;
  border-radius: 50px;
  cursor: pointer;
}
.sidebar-offcanvas .sidebar-content .link-section li .pages .switch-btn input[type=checkbox]:checked {
  background: rgba(var(--theme-color), 0.1);
}
.sidebar-offcanvas .sidebar-content .link-section li .pages .switch-btn input[type=checkbox]:checked::before {
  transform: translateX(80%) translateY(-50%);
  background: rgba(var(--theme-color), 1);
}
.sidebar-offcanvas .sidebar-content .link-section li .pages .switch-btn input[type=checkbox]:before {
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: rgba(var(--light-text), 1);
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  transition: 0.5s;
}
.sidebar-offcanvas .sidebar-content .link-section li .pages h3 {
  color: rgba(var(--dark-text), 1);
}
.sidebar-offcanvas .sidebar-content .link-section li .pages i {
  color: rgba(var(--dark-text), 1);
  font-size: 28px;
  line-height: 1;
}

.order-modal .modal-content .modal-header {
  background-color: rgba(var(--theme-color), 1);
  border: none;
}
.order-modal .modal-content .modal-header .modal-title {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgb(255, 255, 255);
}
.order-modal .modal-content .modal-body .details-box {
  margin-top: 15px;
  padding: 15px;
  background-color: rgba(var(--box-bg), 1);
  border-radius: 10px;
}
.order-modal .modal-content .modal-body .details-box span {
  font-size: 14px;
  color: rgba(var(--success-color), 1);
}
.order-modal .modal-content .modal-body .details-box .details-title {
  font-size: calc(12px + 2 * (100vw - 320px) / 1600);
  padding-bottom: 12px;
  font-weight: 600;
  color: rgba(var(--dark-text), 1);
  border-bottom: 1px dashed rgba(var(--black), 0.15);
}
.order-modal .modal-content .modal-body .details-box .details-box-content {
  padding-top: 12px;
}
.order-modal .modal-content .modal-body .details-box .details-box-content .distance {
  position: relative;
  width: 100%;
  border: none;
}
.order-modal .modal-content .modal-body .details-box .details-box-content .distance::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 6px;
  width: 1px;
  height: 40%;
  border-left: 1px dashed rgba(var(--theme-color), 1);
  transform: translateY(-50%);
}
[dir=rtl] .order-modal .modal-content .modal-body .details-box .details-box-content .distance::after {
  left: unset;
  right: 6px;
}
.order-modal .modal-content .modal-body .details-box .details-box-content .distance ul li {
  display: flex;
  align-items: center;
  gap: 5px;
  padding-top: 10px;
  font-size: calc(12px + 2 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 1);
}
.order-modal .modal-content .modal-body .details-box .details-box-content .distance ul li i {
  font-size: 14px;
}
.order-modal .modal-content .modal-body .withdraw-box {
  padding: 15px;
  background-color: rgba(var(--box-bg), 1);
}
.order-modal .modal-content .modal-body .withdraw-box .box-content .form-check {
  margin-bottom: 0;
}
.order-modal .modal-content .modal-body .withdraw-box .box-content .form-check .form-check-input {
  width: 20px;
  height: 20px;
  background-color: rgba(var(--white), 1);
  border: 1px solid rgba(var(--dashed-line), 1);
}
.order-modal .modal-content .modal-body .withdraw-box .box-content .form-check .form-check-input:focus {
  box-shadow: none;
  border-color: none;
}
.order-modal .modal-content .modal-body .withdraw-box .box-content .form-check .form-check-label {
  color: rgba(var(--light-text), 1);
  font-size: 16px;
  margin-left: 10px;
}
[dir=rtl] .order-modal .modal-content .modal-body .withdraw-box .box-content .form-check .form-check-label {
  margin-right: 10px;
  margin-left: unset;
}
.order-modal .modal-content .modal-body .withdraw-box .box-content .form-check .form-check-input:checked {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(var(--theme-color), 1);
}
.order-modal .modal-content .modal-body .withdraw-box .box-content .form-check .form-check-input:checked::after {
  content: "";
  height: 10px;
  width: 10px;
  background-color: rgba(var(--theme-color), 1);
  border-radius: 100%;
}
.order-modal .modal-content .modal-body .withdraw-box .form-group .form-control {
  background-color: rgba(var(--white), 1);
}
.order-modal .modal-content .modal-body .withdraw-box .form-select {
  background-color: rgba(var(--white), 1);
}

.coupon-modal .modal-content {
  background-color: rgba(var(--white), 1);
}
.coupon-modal .modal-content .modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px dashed rgba(var(--dashed-line), 1);
  background-color: rgba(var(--white), 1);
}
.coupon-modal .modal-content .modal-header .modal-title {
  color: rgba(var(--dark-text), 1);
}
.coupon-modal .modal-content .modal-header .modal-title .coupon-icon {
  width: 40px;
  height: 40px;
}
.coupon-modal .modal-content .modal-header .btn-close {
  padding: 0;
  margin: 0;
  margin-left: auto;
}
.coupon-modal .modal-content .modal-header .btn-close:focus {
  box-shadow: none;
}
.coupon-modal .modal-content .close-btn:focus {
  box-shadow: none;
}
.coupon-modal .modal-content .modal-body h2 {
  text-align: center;
  font-size: 28px;
  margin-top: 15px;
  color: rgba(var(--theme-color), 1);
}
.coupon-modal .modal-content .modal-body h3 {
  color: rgba(var(--light-text), 1);
}
.coupon-modal .modal-content .modal-body h5 {
  text-align: center;
  padding-bottom: 15px;
  margin-top: 5px;
  color: rgba(var(--light-text), 1);
}
.coupon-modal .modal-content .modal-body .details-list {
  border-top: 1px dashed rgba(var(--box-bg), 0.2);
}
.coupon-modal .modal-content .modal-body .details-list li {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}
.coupon-modal .modal-content .modal-body .details-list li .heading1 {
  width: 40%;
  color: rgba(var(--dark-text), 1);
}
.coupon-modal .modal-content .modal-body .details-list li span {
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: rgba(var(--dark-text), 1);
}
.coupon-modal .modal-content .modal-body .details-list li .content {
  color: rgba(var(--dark-text), 1);
  padding-left: 15px;
}
[dir=rtl] .coupon-modal .modal-content .modal-body .details-list li .content {
  padding-left: unset;
  padding-right: 15px;
}
.coupon-modal .modal-content .footer-modal {
  border-top: 1px dashed rgba(var(--dashed-line), 1);
}

/*=====================
    Product-box CSS start
==========================*/
.products {
  padding-top: 33px;
}
.products .product-box {
  position: relative;
  border: 1px solid;
  border-image-source: linear-gradient(180deg, rgba(var(--black), 0) -3.45%, rgba(var(--black), 0.12) 87.93%);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  overflow: visible;
}
[dir=rtl] .products .product-box {
  direction: rtl;
}
.products .product-box-bg {
  padding: 8px;
  background-color: rgba(var(--black), 0);
  margin-bottom: 5px;
}
.products .product-box-bg a {
  border-radius: 8px;
  margin-bottom: 8px;
}
.products .product-box-bg .product-box-detail {
  background-color: transparent !important;
  padding: 0 !important;
}
.products .product-box .product-box-img {
  margin-top: -50px;
  display: block;
}
.products .product-box .product-box-detail h5 {
  color: rgba(var(--dark-text), 1);
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.products .product-box .product-box-detail .timing {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 3px;
}
.products .product-box .product-box-detail .timing li {
  position: relative;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: rgba(var(--dark-text), 1);
}
.products .product-box .product-box-detail .timing li:last-child::after {
  display: none;
}
.products .product-box .product-box-detail .timing li .star {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: rgba(var(--success-color), 1);
  border-radius: 100%;
}
.products .product-box .product-box-detail .timing li .star i {
  color: rgb(225, 225, 225);
}
.products .product-box .product-box-detail .timing li::after {
  content: "";
  position: absolute;
  margin-left: 40px;
  transform: translateY(-50%) rotate(90deg);
  top: 50%;
  background-color: rgba(var(--dashed-line), 1);
  width: 30%;
  height: 1px;
}
[dir=rtl] .products .product-box .product-box-detail .timing li::after {
  margin-left: unset;
  margin-right: 44px;
}
.products .product-box .product-box-detail .bottom-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.products .product-box .product-box-detail .bottom-panel .price {
  color: rgba(var(--dark-text), 1);
  font-weight: 600;
}
.products .product-box .product-box-detail .bottom-panel .cart {
  width: 25px;
  height: 25px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(var(--theme-color), 0.2);
  border-radius: 100%;
}
.products .product-box .product-box-detail .bottom-panel .cart i {
  color: rgba(var(--theme-color), 1);
}

.vertical-product-box {
  display: flex;
  align-items: center;
  border: 1px solid rgba(var(--black), 0.05);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  height: 100%;
}
.vertical-product-box .like-icon {
  position: absolute;
  top: 5px;
  right: 5px;
}
[dir=rtl] .vertical-product-box .like-icon {
  right: unset;
  left: 5px;
}
.vertical-product-box .like-icon i {
  padding: 5px;
  color: rgba(var(--error-color), 1);
  background-color: rgb(255, 255, 255);
  border-radius: 100%;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vertical-product-box .like-icon .fill-icon {
  display: none;
}
.vertical-product-box .like-icon.active .outline-icon {
  display: none;
}
.vertical-product-box .like-icon.active .fill-icon {
  display: flex;
  color: rgba(var(--error-color), 1);
}
.vertical-product-box .like-icon.active .effect:before {
  animation: fireworkLine 0.5s linear 0.1s;
}
.vertical-product-box .like-icon.active .effect:after {
  animation: fireworkPoint 0.5s linear 0.1s;
}
.vertical-product-box .vertical-box-img {
  position: relative;
  width: 40%;
  height: 100%;
  border-radius: 12px 0 0 12px;
  overflow: hidden;
}
[dir=rtl] .vertical-product-box .vertical-box-img {
  border-radius: 0 12px 12px 0;
}
.vertical-product-box .vertical-box-img .img {
  height: 100%;
  object-fit: cover;
}
.vertical-product-box .vertical-box-img .offers {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px;
  color: rgba(var(--white), 1);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.83) 43.87%, rgba(0, 0, 0, 0) 94.57%);
  width: 100%;
}
.vertical-product-box .vertical-box-img .offers h2 {
  font-size: 16px;
  color: rgb(255, 255, 255);
}
.vertical-product-box .vertical-box-img .offers h6 {
  color: rgb(255, 255, 255);
}
[dir=rtl] .vertical-product-box .vertical-box-img .offers {
  left: unset;
  right: 0;
}
.vertical-product-box .vertical-box-details {
  width: 60%;
}
.vertical-product-box .vertical-box-details .vertical-box-head {
  padding: 10px 10px 0 10px;
}
.vertical-product-box .vertical-box-details .vertical-box-head .restaurant {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vertical-product-box .vertical-box-details .vertical-box-head .restaurant h5 {
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.vertical-product-box .vertical-box-details .vertical-box-head .restaurant h6 {
  padding: calc(2px + 3 * (100vw - 320px) / 1600) calc(5px + 5 * (100vw - 320px) / 1600);
  border-radius: 33px;
  display: flex;
  align-items: center;
  gap: calc(3px + 2 * (100vw - 320px) / 1600);
  font-size: 10px;
  font-weight: 600;
  color: rgba(var(--accent-color), 1);
  background-color: rgba(var(--accent-color), 0.08);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.vertical-product-box .vertical-box-details .vertical-box-head .restaurant h6 i {
  font-size: 12px;
  line-height: 1;
  display: flex;
}
.vertical-product-box .vertical-box-details .vertical-box-head h5 {
  color: rgba(var(--dark-text), 1);
}
.vertical-product-box .vertical-box-details .food-items {
  border: none;
  padding: 0;
  margin-top: 5px;
  color: rgba(var(--light-text), 1);
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
[dir=rtl] .vertical-product-box .vertical-box-details .food-items {
  text-align: right;
}
.vertical-product-box .vertical-box-details .rating-star {
  border-bottom: 1px dashed rgba(var(--dashed-line), 1);
  padding-bottom: 6px;
  text-align: left;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
  color: rgba(var(--dark-text), 1);
}
[dir=rtl] .vertical-product-box .vertical-box-details .rating-star {
  text-align: right;
}
.vertical-product-box .vertical-box-details .rating-star .star {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: rgba(var(--success-color), 1);
  border-radius: 100%;
}
.vertical-product-box .vertical-box-details .rating-star .star i {
  color: rgb(255, 255, 255);
}
.vertical-product-box .vertical-box-details .location-distance {
  display: flex;
  gap: 4px;
  padding: 5px 10px;
}
.vertical-product-box .vertical-box-details .location-distance .location {
  position: relative;
  width: 60%;
  text-align: left;
  border-right: 1px solid rgba(var(--dashed-line), 1);
  margin-right: calc(5px + 10 * (100vw - 320px) / 1600);
  padding-right: calc(5px + 10 * (100vw - 320px) / 1600);
}
[dir=rtl] .vertical-product-box .vertical-box-details .location-distance .location {
  text-align: right;
  border-right: unset;
  margin-right: unset;
  padding-right: unset;
  border-left: 1px solid rgba(var(--dashed-line), 1);
  margin-left: calc(5px + 10 * (100vw - 320px) / 1600);
  padding-left: calc(5px + 10 * (100vw - 320px) / 1600);
}
.vertical-product-box .vertical-box-details .location-distance .location .place {
  position: relative;
  text-align: left;
  padding: 6px;
  color: rgba(var(--dark-text), 1);
}
[dir=rtl] .vertical-product-box .vertical-box-details .location-distance .location .place {
  text-align: right;
}
.vertical-product-box .vertical-box-details .location-distance .location .place::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(var(--dashed-line), 1);
  width: 80%;
  height: 1px;
}
[dir=rtl] .vertical-product-box .vertical-box-details .location-distance .location .place::before {
  left: unset;
  right: 0;
}
.vertical-product-box .vertical-box-details .location-distance .location .place::after {
  content: "";
  position: absolute;
  left: 0;
  top: 45%;
  transform: translate(-50%);
  width: 5px;
  height: 5px;
  background-color: rgba(var(--black), 1);
  border-radius: 100%;
}
[dir=rtl] .vertical-product-box .vertical-box-details .location-distance .location .place::after {
  left: unset;
  right: -5px;
}
.vertical-product-box .vertical-box-details .location-distance .location h6 {
  color: rgba(var(--dark-text), 1);
}
.vertical-product-box .vertical-box-details .location-distance .distance {
  position: relative;
  width: 40%;
  border: none;
}
.vertical-product-box .vertical-box-details .location-distance .distance::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 6px;
  width: 1px;
  height: 40%;
  border-left: 1px dashed rgba(var(--theme-color), 1);
  transform: translateY(-50%);
}
[dir=rtl] .vertical-product-box .vertical-box-details .location-distance .distance::after {
  left: unset;
  right: 6px;
}
.vertical-product-box .vertical-box-details .location-distance .distance ul li {
  display: flex;
  align-items: center;
  gap: 5px;
  padding-top: 10px;
  font-size: calc(12px + 2 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 1);
}

.product-box2 {
  display: flex;
  gap: 15px;
}
.product-box2 .product-content {
  width: calc(100% - 130px);
}
.product-box2 .product-content .product-name {
  font-weight: 600;
  font-size: 14px;
  margin: 2px 0;
  color: rgba(var(--dark-text), 1);
}
.product-box2 .product-content .rating-stars {
  display: flex;
}
.product-box2 .product-content .rating-stars .stars {
  color: rgba(var(--rate-color), 1);
}
.product-box2 .product-content .rating-stars h5 {
  margin-left: calc(3px + 1 * (100vw - 320px) / 1600);
}
[dir=rtl] .product-box2 .product-content .rating-stars h5 {
  margin-left: unset;
  margin-right: calc(3px + 1 * (100vw - 320px) / 1600);
}
.product-box2 .product-content .product-price {
  font-size: 14px;
  font-weight: 600;
  color: rgba(var(--light-text), 1);
}
.product-box2 .product-content .product-price span {
  color: rgba(var(--theme-color), 1);
  font-size: 16px;
  font-weight: 600;
}
.product-box2 .product-content p {
  border-top: 1px dashed rgba(var(--dashed-line), 1);
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.product-box2 .product-img {
  position: relative;
  text-align: center;
  width: 122px;
  height: 128px;
}
[dir=rtl] .product-box2 .product-img {
  text-align: right;
}
.product-box2 .product-img .img {
  border-radius: 12px;
}
.product-box2 .product-img .add-btn {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: -15px;
  box-shadow: 0px 2px 11px rgba(252, 128, 25, 0.14);
  border-radius: 6px;
}
.product-box2 .product-img .add-btn .btn-outline {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 17px;
  border: 1px solid rgba(var(--theme-color), 1);
  color: rgba(var(--theme-color), 1);
  background-color: rgba(var(--white), 1);
  margin: 0;
  font-size: 14px;
}
.product-box2 .product-img .add-btn .btn-outline:hover {
  border: 1px solid rgba(var(--theme-color), 1);
  color: rgba(var(--theme-color), 1);
  background-color: rgba(var(--white), 1);
}
.product-box2 .product-img .plus-minus {
  border-radius: 6px;
  border: 1px solid rgba(var(--theme-color), 1);
  padding: 6px 17px;
  display: inline-flex;
  align-items: center;
  background-color: rgba(var(--white), 1);
  text-align: center;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: -15px;
  display: none;
}
.product-box2 .product-img .plus-minus input {
  background-color: rgba(var(--white), 1);
  color: rgba(var(--theme-color), 1);
  border: none;
  font-size: 14px;
  outline: none;
  width: 35px;
  text-align: center;
}
.product-box2 .product-img .plus-minus i {
  color: rgba(var(--theme-color), 1);
}

.horizontal-product-box {
  display: flex;
  gap: 10px;
  padding: calc(10px + 10 * (100vw - 320px) / 1600);
  background: rgba(var(--white), 1);
  border: 1px solid rgba(var(--black), 0.05);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}
.horizontal-product-box .product-img .img {
  width: 78px;
  height: 78px;
  border-radius: 12px;
}
.horizontal-product-box .product-content {
  width: calc(100% - 78px - 10px);
  position: relative;
}
.horizontal-product-box .product-content h5 {
  color: rgba(var(--dark-text), 1);
  font-weight: 600;
  max-width: 80%;
  font-size: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.horizontal-product-box .product-content h6 {
  color: rgba(var(--light-text), 1);
  font-weight: 400;
  margin-top: 4px;
}
.horizontal-product-box .product-content .plus-minus {
  border: 1px solid rgba(var(--theme-color), 1);
  padding: 3px 10px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  background-color: rgba(var(--white), 1);
  text-align: center;
  position: absolute;
  transform: translateX(-50%);
  left: 45px;
  bottom: 0;
}
.horizontal-product-box .product-content .plus-minus input {
  background-color: rgba(var(--white), 1);
  color: rgba(var(--theme-color), 1);
  border: none;
  font-size: 14px;
  outline: none;
  width: 35px;
  text-align: center;
}
.horizontal-product-box .product-content .plus-minus i {
  color: rgba(var(--theme-color), 1);
}
.horizontal-product-box .product-content .red-symbol {
  position: absolute;
  top: 0;
  right: 0;
}
[dir=rtl] .horizontal-product-box .product-content .red-symbol {
  left: 0;
  right: unset;
}
.horizontal-product-box .product-content .product-price {
  color: rgba(var(--theme-color), 1);
  font-weight: 700;
  position: absolute;
  right: 0;
  bottom: 8px;
}

.order-box .vertical-box-img {
  width: 96px;
  height: 100px;
}
.order-box .vertical-box-details {
  width: calc(100% - 96px);
  padding: 15px;
}
.order-box .vertical-box-details .vertical-box-head {
  padding: 0;
  border-bottom: 1px dashed rgba(var(--dashed-line), 1);
}
.order-box .vertical-box-details .vertical-box-head .restaurant h5 {
  font-weight: 600;
}
.order-box .vertical-box-details .reorder {
  padding: 0;
}
.order-box .vertical-box-details .reorder span {
  font-size: 14px;
}
.order-box .vertical-box-details .reorder .rating-star {
  border-bottom: none;
}
.order-box .vertical-box-details .reorder .rating-star .timing {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 3px;
}
.order-box .vertical-box-details .reorder .rating-star .timing li {
  position: relative;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: rgba(var(--dark-text), 1);
}
.order-box .vertical-box-details .reorder .rating-star .timing li:last-child::after {
  display: none;
}
.order-box .vertical-box-details .reorder .rating-star .timing li .star {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: rgba(var(--success-color), 1);
  border-radius: 100%;
}
.order-box .vertical-box-details .reorder .rating-star .timing li::after {
  content: "";
  position: absolute;
  margin-left: 40px;
  transform: translateY(-50%) rotate(90deg);
  top: 50%;
  background-color: rgba(var(--dashed-line), 1);
  width: 30%;
  height: 1px;
}
[dir=rtl] .order-box .vertical-box-details .reorder .rating-star .timing li::after {
  margin-left: unset;
  margin-right: 44px;
}
.order-box .vertical-box-details .reorder .rating-star .order {
  font-size: 12px;
  padding: 4px;
  margin-left: auto;
}
[dir=rtl] .order-box .vertical-box-details .reorder .rating-star .order {
  margin-left: unset;
  margin-right: auto;
}
.order-box .vertical-box-details .reorder .switch-btn input[type=checkbox] {
  position: relative;
  width: 37px;
  height: 22px;
  appearance: none;
  background: rgba(var(--box-bg), 1);
  outline: none;
  border-radius: 50px;
  cursor: pointer;
}
.order-box .vertical-box-details .reorder .switch-btn input[type=checkbox]:checked {
  background: rgba(var(--theme-color), 0.1);
}
.order-box .vertical-box-details .reorder .switch-btn input[type=checkbox]:checked::before {
  transform: translateX(80%) translateY(-50%);
  background: rgba(var(--theme-color), 1);
}
.order-box .vertical-box-details .reorder .switch-btn input[type=checkbox]:before {
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: rgba(var(--light-text), 1);
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  transition: 0.5s;
}

.effect-group {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.effect-group .effect {
  display: block;
  position: absolute;
  top: 38%;
  left: 50%;
  width: 20px;
  transform-origin: 0px 2px;
}
.effect-group .effect:nth-child(2) {
  transform: rotate(72deg);
}
.effect-group .effect:nth-child(3) {
  transform: rotate(144deg);
}
.effect-group .effect:nth-child(4) {
  transform: rotate(216deg);
}
.effect-group .effect:nth-child(5) {
  transform: rotate(288deg);
}
.effect-group .effect:before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  border-radius: 2px;
  height: 3px;
  background: #777777;
}
.effect-group .effect:after {
  content: "";
  display: block;
  position: absolute;
  top: 10px;
  right: 10%;
  border-radius: 50%;
  width: 3px;
  height: 3px;
  background: rgba(var(--theme-color), 1);
  transform: scale(0, 0);
}

@keyframes fireworkLine {
  0% {
    right: 20%;
    transform: scale(0, 0);
  }
  25% {
    right: 20%;
    width: 6px;
    transform: scale(1, 1);
  }
  35% {
    right: 0;
    width: 35%;
  }
  70% {
    right: 0;
    width: 4px;
    transform: scale(1, 1);
  }
  100% {
    right: 0;
    transform: scale(0, 0);
  }
}
@keyframes fireworkPoint {
  30% {
    transform: scale(0, 0);
  }
  60% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0, 0);
  }
}
/*=====================
    Ratio CSS start
==========================*/
.ratio_40 .bg-size:before {
  padding-top: 40%;
  content: "";
  display: block;
}

.ratio_45 .bg-size:before {
  padding-top: 45%;
  content: "";
  display: block;
}

.ratio50 .bg-size:before {
  padding-top: 50%;
  content: "";
  display: block;
}

.ratio_55 .bg-size:before {
  padding-top: 55%;
  content: "";
  display: block;
}

.ratio2_3 .bg-size:before {
  padding-top: 60%;
  content: "";
  display: block;
}

.ratio3_2 .bg-size:before {
  padding-top: 66.66%;
  content: "";
  display: block;
}

.ratio_landscape .bg-size:before {
  padding-top: 75%;
  content: "";
  display: block;
}

.ratio_square .bg-size:before {
  padding-top: 100%;
  content: "";
  display: block;
}

.ratio_apos .bg-size:before {
  padding-top: 127.7777778%;
  content: "";
  display: block;
}

.ratio_portrait .bg-size:before {
  padding-top: 150%;
  content: "";
  display: block;
}

.ratio1_2 .bg-size:before {
  padding-top: 200%;
  content: "";
  display: block;
}

.b-top {
  background-position: top !important;
}

.b-bottom {
  background-position: bottom !important;
}

.b-center {
  background-position: center !important;
}

.b-left {
  background-position: left !important;
}

.b-right {
  background-position: right !important;
}

.b_size_content {
  background-size: contain !important;
}

/*=====================
  Slider CSS start
==========================*/
.swiper-3d .swiper-slide-shadow-left {
  background-image: none;
}
.swiper-3d .swiper-slide-shadow-right {
  background-image: none;
}

.swiper-wrapper {
  direction: ltr;
}

.offer-wrapper .swiper-slide img {
  transform: scale(0.8);
  opacity: 0.4;
}
.offer-wrapper .swiper-slide-active img {
  transform: scale(1);
  opacity: 1;
}

/*=====================
    tab CSS start
==========================*/
.nav {
  border-bottom: 1px solid rgba(var(--dashed-line), 0.5);
  gap: 5px;
}
.nav .nav-item .nav-link {
  color: rgba(var(--light-text), 1);
  position: relative;
  padding: 15px;
}
.nav .nav-item .nav-link.active {
  color: rgba(var(--theme-color), 1);
  background-color: rgba(var(--white), 1);
}
.nav .nav-item .nav-link.active::after {
  content: "";
  position: absolute;
  left: 0;
  top: 45%;
  transform: translate(-50%);
  width: 5px;
  height: 5px;
  background-color: rgba(var(--theme-color), 1);
  border-radius: 100%;
}
.nav .nav-item .nav-link.active .nav-link::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%);
  width: 5px;
  height: 5px;
  background-color: rgba(var(--theme-color), 1);
  border-radius: 100%;
}
[dir=rtl] .nav .nav-item .nav-link.active .nav-link::after {
  left: unset;
  right: 0;
}

.tab-style1 {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 100%;
  overflow-x: auto;
}
.tab-style1 .nav-item {
  color: rgba(var(--white), 0.1);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  cursor: pointer;
  border-radius: 0px;
  transition: all 0.5s ease-in-out;
  border-radius: 0;
  border-bottom: 1px solid rgba(var(--white), 0.1);
}
.tab-style1 .nav-item .nav-link {
  width: 100%;
  padding-top: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  white-space: nowrap;
  color: rgba(var(--light-text), 1);
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid rgba(var(--box-bg), 0.1);
}
.tab-style1 .nav-item .nav-link:hover {
  border-left: none;
  border-right: none;
  border-top: none;
}
.tab-style1 .nav-item .nav-link:focus {
  border-color: transparent;
}
.tab-style1 .nav-item .nav-link.active {
  background-color: transparent;
  color: rgba(var(--theme-color), 1);
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 2px solid rgba(var(--theme-color), 1);
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}
.tab-style1 .nav-item .nav-link.active::after {
  content: none;
}

.tab-style2 {
  flex-wrap: nowrap;
  width: 100%;
  margin: 30px auto 0;
  border-radius: 6px;
  overflow: hidden;
  gap: 20px;
  display: flex;
  border: none;
}
.tab-style2 .nav-item {
  border-radius: 10px;
  background-color: rgba(var(--box-bg), 1);
}
.tab-style2 .nav-item .nav-link {
  width: 100%;
  padding: 12px;
  font-weight: 500;
  line-height: 1;
  font-size: 14px;
  white-space: nowrap;
  color: rgba(var(--light-text), 1);
}
.tab-style2 .nav-item .nav-link.active {
  background-color: rgba(var(--theme-color), 1);
  color: rgb(255, 255, 255);
  transition: all 0.5s ease-in-out;
}
.tab-style2 .nav-item .nav-link.active:hover {
  color: rgb(255, 255, 255);
}
.tab-style2 .nav-item .nav-link.active::after {
  content: none;
}
.tab-style2 .nav-item .nav-link:hover {
  color: rgba(var(--light-text), 1);
}

/*=====================
    Title CSS start
==========================*/
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}
.title h3 {
  color: rgba(var(--dark-text), 1);
  font-weight: 600;
}
.title a {
  color: rgba(var(--theme-color), 1);
}
.title .add-button {
  display: flex;
  align-items: center;
  gap: 5px;
  color: rgba(var(--theme-color), 1);
  border-radius: 6px;
}
.title .add-button .icon {
  line-height: 1;
  font-size: 20px;
  color: rgba(var(--theme-color), 1);
}

/* layout scss files */
/*=====================
    Header CSS start
==========================*/
.header-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.header-panel a {
  z-index: 1;
}
.header-panel i {
  color: rgba(var(--dark-text), 1);
  font-size: 25px;
}
.header-panel .sidebar-btn {
  width: 35px;
  height: 35px;
  color: rgba(var(--theme-color), 1);
  padding: 5px;
  background-color: rgba(var(--theme-color), 0.1);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  z-index: 1;
}
.header-panel .sidebar-btn i {
  line-height: 1;
  font-size: 16px;
  color: rgba(var(--theme-color), 1);
}
.header-panel .calendar-icon {
  width: 35px;
  height: 35px;
  font-size: 20px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(var(--dark-text), 1);
  background-color: rgba(var(--box-bg), 1);
  border-radius: 6px;
  backdrop-filter: blur(2px);
}
.header-panel h2 {
  position: absolute;
  font-weight: 600;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  line-height: 1;
  text-align: center;
  color: rgba(var(--dark-text), 1);
  font-size: 18px;
}
.header-panel .create-address {
  color: rgba(var(--theme-color), 1);
}
.header-panel .add-square-img {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  border: 1.5px solid rgba(var(--theme-color), 1);
  border-radius: 6px;
  width: 100%;
  padding: 0 5px;
  color: rgba(var(--theme-color), 1);
}
.header-panel .icon {
  padding: 8px;
  font-size: 18px;
  background-color: rgba(var(--box-bg), 1);
  border-radius: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
}
.header .head-content {
  display: flex;
  align-items: center;
  gap: 8px;
}
.header .head-content .sidebar-btn {
  width: 34px;
  height: 34px;
  color: rgba(var(--theme-color), 1);
  padding: 15px;
  background-color: rgba(var(--theme-color), 0.1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border: none;
}
.header .head-content .sidebar-btn i {
  font-size: 14px;
}
.header .head-content .header-location a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background: none;
  padding: 0;
}
.header .head-content .header-location a h2 {
  font-size: 16px;
  color: rgba(var(--dark-text), 1);
}
.header .head-content .header-location a .d-arrow {
  color: rgba(var(--dark-text), 1);
}
.header .head-content .header-location .pin {
  color: rgba(var(--theme-color), 1);
}
.header .head-content .header-location i {
  font-size: 24px;
}
.header a {
  width: 34px;
  height: 34px;
  color: rgba(var(--theme-color), 1);
  padding: 15px;
  background-color: rgba(var(--theme-color), 0.1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-panel-lg {
  position: relative;
  background-image: url(../images/background/restaurant-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 184px;
}
.header-panel-lg .panel {
  position: absolute;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  width: calc(100% - 40px);
}
.header-panel-lg .panel a {
  color: rgb(225, 225, 225);
  background: rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  border-radius: 100%;
  width: 34px;
  height: 34px;
  backdrop-filter: blur(3px);
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  position: relative;
}
.header-panel-lg .panel a:after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 100%;
  padding: 1px;
  background: linear-gradient(128.66deg, rgba(var(--white), 1) -1.63%, rgba(var(--white), 0) 92.16%);
  -webkit-mask: linear-gradient(rgba(var(--white), 1) 0 0) content-box, linear-gradient(rgba(var(--white), 1) 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: 1;
}

.chatting-header {
  width: 100%;
  border: none;
  border-radius: unset;
  position: fixed;
  top: 0;
  left: 0;
  max-width: unset;
  transform: none;
  bottom: unset;
}
.chatting-header .tracking-head {
  border-bottom: none;
  padding: 0;
  z-index: 1;
}
.chatting-header .tracking-head .back-arrow {
  color: rgba(var(--dark-text), 1);
  font-size: 22px;
}

/*=====================
  Nav CSS start
==========================*/
.navbar-menu {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  max-width: 600px;
  background-color: rgba(var(--white), 1);
  box-shadow: 0px 5px 24px rgba(0, 0, 0, 0.2);
}
@media (max-width: 767px) {
  .navbar-menu {
    max-width: 100%;
  }
}
.navbar-menu ul {
  display: flex;
  justify-content: space-between;
}
.navbar-menu ul li {
  position: relative;
  color: rgba(var(--light-text), 1);
  text-align: center;
  width: 20%;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-menu ul li.active::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 30px;
  height: 4px;
  background: rgba(var(--theme-color), 1);
  border-radius: 10px;
  transform: translateX(-50%);
}
.navbar-menu ul li.active a.icon {
  color: rgba(var(--theme-color), 1);
}
.navbar-menu ul li.active a.icon .active {
  display: block;
}
.navbar-menu ul li.active a.icon .unactive {
  display: none;
}
.navbar-menu ul li.active a span {
  color: rgba(var(--dark-text), 1);
  font-weight: 500;
}
.navbar-menu ul a {
  color: rgba(var(--light-text), 1);
}
.navbar-menu ul a.icon {
  font-size: 20px;
  margin-left: auto;
  margin-right: auto;
}
.navbar-menu ul a.icon.logo {
  width: 100%;
}
.navbar-menu ul a.icon img {
  margin-inline: auto;
  /*width: 25px; */
  height: 25px;
}
.navbar-menu ul a.icon img.active {
  display: none;
}
.navbar-menu ul a.icon img.unactive {
  display: block;
}
.navbar-menu ul a.icon span {
  font-size: 14px;
  font-weight: 500;
}

/* pages */
/*=====================
    Address CSS start
==========================*/
.address-list li {
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 20px;
}
.address-list li .address-icon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}
.address-list li .address-icon::before {
  content: "";
  position: absolute;
  left: 50px;
  top: 15px;
  width: 18px;
  height: 1px;
  background-color: rgba(var(--light-text), 1);
  z-index: 1;
}
[dir=rtl] .address-list li .address-icon::before {
  left: unset;
  right: 50px;
}
.address-list li .address-icon::after {
  content: "";
  position: absolute;
  left: 48px;
  top: 36px;
  width: 20px;
  height: 1px;
  background-color: rgba(var(--light-text), 1);
  z-index: 1;
}
[dir=rtl] .address-list li .address-icon::after {
  left: unset;
  right: 48px;
}
.address-list li .address-icon span {
  display: flex;
  justify-content: center;
  align-items: center;
}
.address-list li .address-icon span i {
  font-size: 24px;
  color: rgba(var(--theme-color), 1);
}
.address-list li .address-icon span::before {
  content: "";
  position: absolute;
  left: 70px;
  top: 13px;
  transform: translate(-50%);
  width: 5px;
  height: 5px;
  background-color: rgba(var(--light-text), 1);
  border-radius: 100%;
  z-index: 1;
}
[dir=rtl] .address-list li .address-icon span::before {
  left: unset;
  right: 65px;
}
.address-list li .address-icon span::after {
  content: "";
  position: absolute;
  left: 70px;
  top: 34px;
  transform: translate(-50%);
  width: 5px;
  height: 5px;
  background-color: rgba(var(--light-text), 1);
  border-radius: 100%;
  z-index: 1;
}
[dir=rtl] .address-list li .address-icon span::after {
  left: unset;
  right: 65px;
}
.address-list li .address-icon span .address-img {
  stroke: rgba(var(--theme-color), 1);
  fill: transparent;
  stroke-width: 1.5px;
  width: 21px;
  height: 21px;
}
.address-list .address-content {
  position: relative;
  overflow: hidden;
  width: calc(100% - 60px);
  margin-left: 10px;
  background: rgba(var(--white), 1);
  padding: 12px 12px 12px 18px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}
[dir=rtl] .address-list .address-content {
  margin-left: unset;
  margin-right: 10px;
  padding: 12px 18px 12px 12px;
}
.address-list .address-content .address-checkbox {
  min-height: unset;
  padding-left: 0;
  margin-bottom: 0;
  position: absolute;
  top: 0;
  right: 0;
}
[dir=rtl] .address-list .address-content .address-checkbox {
  left: 0;
  right: unset;
  margin-left: unset;
  margin-right: 0;
}
.address-list .address-content .address-checkbox .form-check-input {
  width: 26px;
  height: 26px;
  border-radius: 0 8px 0;
  margin: 0;
  float: unset;
  background-color: rgba(var(--white), 1);
  border: none;
  background: transparent;
}
[dir=rtl] .address-list .address-content .address-checkbox .form-check-input {
  border-radius: 8px 0 8px 0;
}
.address-list .address-content .address-checkbox .form-check-input:active {
  filter: brightness(100%);
}
.address-list .address-content .address-checkbox .form-check-input:focus {
  box-shadow: none;
}
.address-list .address-content .address-checkbox .form-check-input:checked {
  background-image: url(../images/svg/check.svg);
  background-color: rgba(var(--theme-color), 1);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center;
}
.address-list .address-content .address-place {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.address-list .address-content .address-place h5 {
  color: rgba(var(--dark-text), 1);
  font-weight: 600;
}
.address-list .address-content .address-place .edit {
  font-size: 20px;
}
.address-list .address-content p {
  color: rgba(var(--light-text), 1);
  border-top: 1px dashed rgba(var(--dashed-line), 1);
  padding: 8px 0 0 0;
  margin: 8px 0 0 0;
  font-size: 13px;
}
.address-list .address-content .select-option {
  color: rgba(var(--dark-text), 1);
  border-top: 1px dashed rgba(var(--dashed-line), 1);
  font-size: 13px;
  padding: 8px 0 0 0;
  margin: 8px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.address-list .address-content .select-option .form-check {
  display: flex;
  min-height: unset;
  padding-left: 0;
  margin-bottom: 0;
  align-items: center;
}
[dir=rtl] .address-list .address-content .select-option .form-check {
  padding-left: unset;
  padding-right: 0;
}
.address-list .address-content .select-option .form-check .form-check-label {
  margin-left: 5px;
  letter-spacing: 0.03em;
}
[dir=rtl] .address-list .address-content .select-option .form-check .form-check-label {
  margin-left: unset;
  margin-right: 5px;
}
.address-list .address-content .select-option .form-check .form-check-input {
  width: 18px;
  height: 18px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin: 0;
}
.address-list .address-content .select-option .form-check .form-check-input:checked {
  background-image: url(../images/svg/check.svg);
  background-color: rgba(var(--theme-color), 1);
  color: rgba(var(--white), 1);
  border: none;
  float: unset;
  margin: 0;
}

.address-filter h3 {
  margin-bottom: 12px;
  color: rgba(var(--dark-text), 1);
}
.address-filter .food-symbol {
  border-bottom: 1px dashed rgba(var(--black), 0.22) !important;
  gap: calc(6px + 6 * (100vw - 320px) / 1600) !important;
}
.address-filter .food-symbol li {
  border: 1px solid rgba(var(--box-bg), 1);
  border-radius: 7px;
}
.address-filter .food-symbol li .food-types h5 {
  line-height: 1;
}
.address-filter .food-symbol li .food-types i {
  line-height: 1;
}
.address-filter .food-symbol li .food-types.active {
  background-color: rgba(var(--theme-color), 1);
}
.address-filter .food-symbol li .food-types.active i {
  color: rgb(255, 255, 255);
}
.address-filter .food-symbol li .food-types.active h5 {
  color: rgb(255, 255, 255);
}

.address-list {
  margin-top: -10px;
}

/*=============================
    Authentication CSS start
===============================*/
.login-img {
  width: 100%;
  object-fit: cover;
}
.login-img.auth-img {
  height: 186px;
}
.login-img.restaurant-login-img {
  height: 100%;
  object-fit: cover;
}

.line-vector {
  position: relative;
  left: 20px;
  height: 30px;
}
[dir=rtl] .line-vector {
  left: unset;
  right: 20px;
}

h2 {
  font-size: 20px;
}

.division {
  position: relative;
  text-align: center;
  margin-top: calc(30px + 20 * (100vw - 320px) / 1600);
  z-index: 0;
}
.division span {
  background-color: rgba(var(--white), 1);
  color: rgb(103, 106, 109);
  padding: 0 10px;
  font-weight: 600;
  font-size: 14px;
}
.division::after {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  content: " ";
  width: 100%;
  background: linear-gradient(90deg, rgba(132, 132, 133, 0) 0%, rgb(132, 132, 133) 50%, rgba(29, 16, 57, 0) 100%);
  z-index: -1;
  height: 2px;
}
[dir=rtl] .division::after {
  left: unset;
  right: 0;
}

p {
  margin-top: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: rgba(var(--light-text), 1);
}

.otp-verification h3 {
  color: rgba(var(--light-text), 1);
  margin-top: 45px;
  font-weight: 600;
}
.otp-verification .otp-number {
  color: rgba(var(--dark-text), 1);
  font-weight: 600;
}

.location {
  position: relative;
}
.location h2 {
  color: rgba(var(--dark-text), 1);
}
.location h5 {
  width: 100%;
  margin-top: 12px;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(var(--light-text), 1);
}
.location .location-img {
  margin-top: 90px;
}
.location .animation-circle-inverse {
  z-index: 2;
}
.location .animation-circle-inverse i {
  position: absolute;
  background: rgba(var(--theme-color), 1);
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11);
  border-radius: 100%;
  opacity: 0.4;
  animation: ripple1 3s linear infinite;
  top: 55%;
  left: 45%;
  z-index: 1;
  transform: translate(-50%);
}
[dir=rtl] .location .animation-circle-inverse i {
  left: unset;
  right: 45%;
}
.location .animation-circle-inverse i:nth-child(1) {
  height: 10px;
  width: 10px;
  animation: ripple1 3s linear infinite;
}
.location .animation-circle-inverse i:nth-child(2) {
  height: 20px;
  width: 20px;
  animation: ripple2 3s linear infinite;
}
.location .animation-circle-inverse i:nth-child(3) {
  height: 25px;
  width: 25px;
  animation: ripple3 3s linear infinite;
}

.line-design {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
}
.line-design li {
  width: 100%;
  height: 4px;
  background-color: rgba(var(--box-bg), 1);
  border-radius: 6px;
}
.line-design li.active {
  background-color: rgba(var(--theme-color), 1);
}

.details-heading {
  margin-top: 20px;
  margin-bottom: 15px;
}

.fixed-btn {
  position: fixed;
  width: 100%;
  max-width: 600px;
  padding: 20px 0;
  left: 50%;
  bottom: 0;
  transform: translate(-50%);
  z-index: 2;
  background-color: rgba(var(--white), 1);
}

/*=============================
    Add-cart CSS start
===============================*/
.apply-coupon {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px dashed rgba(var(--light-text), 1);
  background-color: rgba(var(--box-bg), 1);
  border-radius: 10px;
}
.apply-coupon .coupon-code {
  color: rgba(var(--success-color), 1);
  padding-top: 5px;
}
.apply-coupon i {
  color: rgba(var(--light-text), 1);
  font-size: 20px;
}

.bill-details {
  position: relative;
}
.bill-details .total-detail {
  position: relative;
  background-color: rgba(var(--box-bg), 1);
  padding: calc(15px + 5 * (100vw - 320px) / 1600);
  border-radius: 10px 10px 0px 0px;
  margin-bottom: 0;
}
.bill-details .total-detail .sub-total {
  display: flex;
  justify-content: space-between;
}
.bill-details .total-detail h5 {
  color: rgba(var(--dark-text), 1);
}
.bill-details .total-detail .free {
  color: rgba(var(--theme-color), 1);
  position: absolute;
  top: 54px;
  right: 20px;
}
[dir=rtl] .bill-details .total-detail .free {
  right: unset;
  left: 20px;
}
.bill-details .total-detail .delivery-info {
  width: 70%;
  margin-bottom: 15px;
  line-height: 1.2;
}
.bill-details .total-detail .grand-total {
  display: flex;
  justify-content: space-between;
  border-top: 1px dashed rgba(var(--black), 0.22);
  padding: 10px 0 0;
}
.bill-details .total-detail .grand-total h5 {
  color: rgba(var(--dark-text), 1);
}
.bill-details .total-detail .grand-total .amount {
  color: rgba(var(--theme-color), 1);
}
.bill-details .total-detail .dots-design {
  position: absolute;
  bottom: -1px;
  width: 95%;
  left: 50%;
  transform: translateX(-50%);
}
.bill-details p {
  border-top: 1px dashed rgba(var(--dashed-line), 1);
  padding: 15px 0 0;
}
.bill-details .delivery-time {
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  z-index: 0;
}
.bill-details .delivery-time::after {
  content: "";
  position: absolute;
  width: 100%;
  border: 1px dashed rgba(var(--dashed-line), 1);
  bottom: -4px;
  overflow: hidden;
  z-index: 1;
  right: 0;
}
.bill-details .delivery-time .delivery-line {
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: rgba(var(--light-text), 1);
  border-top: none;
  overflow: hidden;
  background-color: rgba(var(--white), 1);
}
.bill-details .delivery-time .delivery-bike {
  position: relative;
  width: 67px;
  height: 49px;
  bottom: 0;
  right: 0;
}
[dir=rtl] .bill-details .delivery-time .delivery-bike {
  left: 0;
  right: unset;
}

.cart-add-box {
  display: flex;
  gap: 10px;
  padding: calc(10px + 10 * (100vw - 320px) / 1600);
  background: rgba(var(--white), 1);
  border: 1px solid rgba(var(--black), 0.05);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}
.cart-add-box .add-img .img {
  width: 55px;
  height: 55px;
  border-radius: 12px;
}
.cart-add-box .add-content {
  width: calc(100% - 55px - 10px);
  position: relative;
}
.cart-add-box .add-content .address {
  width: 70%;
  line-height: 1.2;
}
.cart-add-box .add-content .change-add {
  color: rgba(var(--theme-color), 1);
  font-size: 13px;
  position: absolute;
  top: 0;
  right: 0;
}
[dir=rtl] .cart-add-box .add-content .change-add {
  left: 0;
  right: unset;
}

/*=====================
    voucher CSS start
==========================*/
.coupon-box {
  position: relative;
  display: flex;
  height: 100%;
  overflow: hidden;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
}
.coupon-box .coupon-left {
  position: absolute;
  left: 0;
  top: 50%;
  height: 111%;
  transform: translateY(-50%);
}
[dir=rtl] .coupon-box .coupon-left {
  left: unset;
  right: -1px;
  transform: translateY(-50%) scaleX(-1);
}
.coupon-box .coupon-right {
  position: absolute;
  right: 0;
  top: 50%;
  height: 111%;
  transform: translateY(-50%);
}
[dir=rtl] .coupon-box .coupon-right {
  right: unset;
  left: -1px;
  transform: translateY(-50%) scaleX(-1);
}
.coupon-box .coupon-discount {
  position: relative;
  writing-mode: vertical-rl;
  text-align: center;
  padding: calc(15px + 5 * (100vw - 320px) / 1600);
  color: rgb(255, 255, 255);
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}
.coupon-box .coupon-discount::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -7px;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: rgba(var(--white), 1);
  transform: translateY(-50%);
}
[dir=rtl] .coupon-box .coupon-discount::after {
  left: -7px;
  right: unset;
}
.coupon-box .coupon-discount.color-1 {
  background-color: rgba(var(--light-text), 1);
}
.coupon-box .coupon-discount.color-2 {
  background-color: rgb(30, 60, 110);
}
.coupon-box .coupon-discount.color-3 {
  background-color: rgb(173, 110, 51);
}
.coupon-box .coupon-discount.color-4 {
  background-color: rgb(123, 51, 157);
}
.coupon-box .coupon-details {
  width: calc(100% - 53px);
  padding: 0 calc(20px + 5 * (100vw - 320px) / 1600) 0 calc(15px + 10 * (100vw - 320px) / 1600);
}
.coupon-box .coupon-details .coupon-content {
  padding: calc(10px + 10 * (100vw - 320px) / 1600) 0 0;
  display: flex;
  justify-content: space-between;
  gap: 5px;
}
.coupon-box .coupon-details .coupon-content .coupon-name {
  display: flex;
  gap: 10px;
}
.coupon-box .coupon-details .coupon-content .coupon-name .coupon-img {
  width: 42px;
  height: 42px;
  object-fit: contain;
  padding: 7px;
  background-color: rgba(var(--box-bg), 1);
  border-radius: 8px;
}
.coupon-box .coupon-details p {
  margin: 0;
  padding: 0;
  color: rgba(var(--light-text), 1);
  padding: 10px 0;
  border-bottom: 1px dashed rgba(var(--dashed-line), 1);
}
.coupon-box .coupon-details p span {
  color: rgba(var(--dark-text), 1);
  font-weight: 500;
}
.coupon-box .coupon-details .coupon-apply {
  padding: calc(10px + 10 * (100vw - 320px) / 1600) 0;
  display: flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.coupon-box .coupon-details .coupon-apply .unlock {
  width: 65%;
  color: rgba(var(--dark-text), 1);
}

.voucher-box {
  position: relative;
  background: rgba(var(--white), 1);
  border: 1px solid rgba(var(--black), 0.05);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  overflow: hidden;
}
.voucher-box::after {
  content: "";
  position: absolute;
  width: 21px;
  height: 21px;
  border-radius: 100%;
  background: rgba(var(--white), 1);
  left: -12px;
  bottom: 25%;
  box-shadow: inset 0px 2px 12px 0px rgb(217, 217, 217);
  z-index: 1;
  display: block;
}
[dir=rtl] .voucher-box::after {
  right: -12px;
  left: unset;
}
.voucher-box::before {
  content: "";
  position: absolute;
  width: 21px;
  height: 21px;
  border-radius: 100%;
  background: rgba(var(--white), 1);
  right: -12px;
  bottom: 25%;
  box-shadow: inset 0px 2px 12px 0px rgb(217, 217, 217);
  z-index: 1;
  display: block;
}
[dir=rtl] .voucher-box::before {
  left: -12px;
  right: unset;
}
.voucher-box .l-vector {
  position: absolute;
  left: -5px;
  bottom: 60px;
  height: 20px;
  width: 20px;
  z-index: 1;
}
[dir=rtl] .voucher-box .l-vector {
  left: unset;
  right: -5px;
}
.voucher-box .r-vector {
  position: absolute;
  height: 20px;
  width: 20px;
  right: -5px;
  bottom: 60px;
  z-index: 1;
  transform: rotate(180deg);
}
[dir=rtl] .voucher-box .r-vector {
  left: -5px;
  right: unset;
}
.voucher-box .voucher-img {
  text-align: center;
  padding-top: 12px;
}
.voucher-box .voucher-img .voucher-icon {
  width: 60px;
  height: 60px;
  object-fit: contain;
}
.voucher-box .voucher-content {
  padding: calc(10px + 2 * (100vw - 320px) / 1600);
}
.voucher-box .voucher-content h5 {
  font-weight: 600;
  color: rgba(var(--dark-text), 1);
  margin-top: 12px;
}
.voucher-box .voucher-content p {
  color: rgba(var(--light-text), 1);
  margin-top: 4px;
  padding: 0;
  margin: 0;
}
.voucher-box .voucher-discount {
  position: relative;
  width: 100%;
  height: 70px;
  background: rgb(53, 121, 42);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 8px 8px;
}
.voucher-box .voucher-discount.color-1 {
  background-color: rgb(37, 131, 125);
}
.voucher-box .voucher-discount.color-2 {
  background-color: rgb(30, 60, 110);
}
.voucher-box .voucher-discount.color-3 {
  background-color: rgb(173, 110, 51);
}
.voucher-box .voucher-discount.color-4 {
  background-color: rgb(123, 51, 157);
}
.voucher-box .voucher-discount.color-5 {
  background-color: rgb(53, 121, 42);
}
.voucher-box .voucher-discount .top-vector {
  position: absolute;
  width: 90%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.voucher-box .voucher-discount h2 {
  color: rgb(225, 225, 225);
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
}

.expired-box {
  position: relative;
}
.expired-box .expired-tag {
  position: absolute;
  background-color: rgba(var(--theme-color), 0.6);
  backdrop-filter: blur(3px);
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 1;
  height: 34px;
}
.expired-box .expired-tag p {
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  padding: 0;
  color: rgba(var(--dark-text), 1);
}
.expired-box .expired-voucher {
  position: relative;
  filter: grayscale(100%);
}

/*==========================
    delivery boy CSS start
============================*/
.gift-section {
  margin-top: 70px;
  text-align: center;
}
.gift-section h2 {
  margin-top: 30px;
}
.gift-section p {
  font-size: 13px;
  margin-top: 15px;
}
.gift-section h5 {
  margin-top: 40px;
  font-weight: 600;
  color: rgba(var(--dark-text), 1);
}
.gift-section .Refer-code-box {
  width: 220px;
  padding: 15px;
  margin-top: 12px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(var(--theme-color), 0.1);
  border: 1px dashed rgba(var(--theme-color), 1);
  border-radius: 6px;
  backdrop-filter: blur(2px);
}
.gift-section .Refer-code-box h6 {
  font-weight: 600;
  font-size: 14px;
  color: rgba(var(--theme-color), 1);
}
.gift-section .Refer-code-box .copy-icon {
  font-size: 25px;
  line-height: 1;
  padding-left: 15px;
  color: rgba(var(--theme-color), 1);
  border-left: 1px solid rgba(var(--theme-color), 0.3);
}
[dir=rtl] .gift-section .Refer-code-box .copy-icon {
  border-left: unset;
  border-right: 1px solid rgba(var(--theme-color), 0.3);
}

.share-media-section {
  margin-top: 80px;
  padding-top: 40px;
  text-align: center;
  border-top: 1px dashed rgba(var(--black), 0.15);
}
.share-media-section h5 {
  font-weight: 600;
  color: rgba(var(--dark-text), 1);
}
.share-media-section .media-list {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.share-media-section .media-list li {
  position: relative;
  width: 100%;
}
.share-media-section .media-list li:last-child::after {
  display: none;
}
.share-media-section .media-list li::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid rgba(var(--black), 0.15);
  width: 1px;
  height: 45px;
}
@media (max-width: 375px) {
  .share-media-section .media-list li::after {
    display: none;
  }
}
.share-media-section .media-list li .media-box {
  width: 40px;
  height: 40px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
.share-media-section .media-list li .media-box .media-icon {
  height: 20px;
  width: 20px;
  font-size: 20px;
  line-height: 1;
  color: rgb(255, 255, 255);
}
.share-media-section .media-list li .media-box.color-1 {
  background: radial-gradient(48.37% 48.37% at 37% 35%, #5bfe75 0%, #2acc3b 100%);
}
.share-media-section .media-list li .media-box.color-2 {
  background: radial-gradient(135.15% 135.15% at 13% -11%, #fe54c4 0%, #e51fa2 52.6%, #f37843 100%);
}
.share-media-section .media-list li .media-box.color-3 {
  background: radial-gradient(50.7% 50.7% at 31% 23%, #7ed1ff 0%, #39b1f4 100%);
}
.share-media-section .media-list li .media-box.color-4 {
  background: radial-gradient(56.65% 65.43% at 33.02% 13.21%, #8aceff 0%, #2c99fd 100%);
}
.share-media-section .media-list li h5 {
  margin-top: 5px;
  font-weight: 500;
  text-align: center;
  color: rgba(var(--dark-text), 1);
}

.profile-details-list {
  display: flex;
  align-items: center;
  gap: calc(10px + 5 * (100vw - 320px) / 1600);
  margin-bottom: 20px;
}
.profile-details-list li {
  position: relative;
  border-radius: 8px;
  border: 1px solid rgba(var(--black), 0.1);
  background-color: rgba(var(--white), 1);
  box-shadow: 0px 2px 12px 0px rgba(var(--black), 0.04);
  padding: calc(10px + 5 * (100vw - 320px) / 1600);
  width: 33%;
}
.profile-details-list li::after {
  content: "";
  position: absolute;
  left: -2px;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(var(--theme-color), 1);
  width: 4px;
  height: 45px;
  border-radius: 4px;
}
[dir=rtl] .profile-details-list li::after {
  left: unset;
  right: -2px;
}
.profile-details-list li .icon {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-bottom: 15px;
  background-color: rgba(var(--theme-color), 1);
  border-radius: 4px;
  box-shadow: 0px 4px 14px 0px rgba(var(--theme-color), 0.22);
}
.profile-details-list li h2 {
  font-weight: 600;
  color: rgba(var(--theme-color), 1);
}
.profile-details-list li h6 {
  margin-top: 4px;
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
}
.profile-details-list.order-list li::after {
  top: 70%;
}
.profile-details-list.restaurant-order-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: calc(15px + 5 * (100vw - 320px) / 1600);
}
.profile-details-list.restaurant-order-list li h2 {
  font-size: 24px;
}

.earning-card-background {
  position: relative;
  background-image: url(../images/delivery-boy/background/earning-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
  border-radius: 6px;
}
.earning-card-background.withdraw-background {
  position: relative;
  background-image: url(../images/restaurant-partner/withdraw-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
  border-radius: 6px;
}
.earning-card-background.total-earning-background {
  position: relative;
  background-image: url(../images/delivery-boy/background/earning-bg-1.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
}
.earning-card-background.total-earning-background .earning-card-details {
  border: 1px solid rgba(var(--theme-color), 0.3);
  border-radius: 6px;
}
.earning-card-background.total-earning-background .earning-card-details .icon {
  padding-right: calc(8px + 7 * (100vw - 320px) / 1600);
  border-right: 1px solid rgba(var(--theme-color), 0.3);
}
[dir=rtl] .earning-card-background.total-earning-background .earning-card-details .icon {
  border-right: unset;
  border-left: 1px solid rgba(var(--theme-color), 0.3);
  padding-right: unset;
  padding-left: calc(8px + 7 * (100vw - 320px) / 1600);
}
.earning-card-background.total-earning-background .earning-card-details h3 {
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
}
.earning-card-background.total-earning-background .earning-card-details .right-arrow {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(var(--white), 1);
  background-color: rgba(var(--theme-color), 1);
  border-radius: 4px;
  box-shadow: 0px 4px 14px 0px rgba(var(--theme-color), 0.22);
}
.earning-card-background .earning-card-details {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.earning-card-background .earning-card-details .earning-card-content {
  padding-left: calc(8px + 7 * (100vw - 320px) / 1600);
}
[dir=rtl] .earning-card-background .earning-card-details .earning-card-content {
  padding-left: unset;
  padding-right: calc(8px + 7 * (100vw - 320px) / 1600);
}
.earning-card-background .earning-card-details .earning-card-content h3 {
  font-weight: 400;
  color: rgb(255, 255, 255);
}
.earning-card-background .earning-card-details .earning-card-content h2 {
  margin-top: 10px;
  font-weight: 600;
  color: rgba(var(--theme-color), 1);
}

.heading {
  margin-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.heading h3 {
  font-weight: 600;
  color: rgba(var(--dark-text), 1);
}
.heading .calendar-icon {
  width: 35px;
  height: 35px;
  font-size: 20px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(var(--dark-text), 1);
  background-color: rgba(var(--box-bg), 1);
  border-radius: 6px;
  backdrop-filter: blur(2px);
}

.earning-list {
  margin-top: 25px;
}
.earning-list li {
  width: 100%;
  margin-bottom: 15px;
  border-radius: 8px;
  border: 1px solid rgba(var(--black), 0.05);
  background: rgba(var(--white), 1);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.04);
  padding: 12px;
}
.earning-list li:last-child {
  margin-bottom: 0;
}
.earning-list li .earning-box {
  display: flex;
  align-items: center;
  gap: 10px;
}
.earning-list li .earning-box .earning-img {
  width: 40px;
  height: 40px;
  background-color: rgba(var(--theme-color), 0.1);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}
.earning-list li .earning-box .earning-img .earning-icon {
  color: rgba(var(--theme-color), 1);
}
.earning-list li .earning-box .earning-content {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 40px - 10px);
}
.earning-list li .earning-box .earning-content span {
  font-weight: 400;
  font-size: 13px;
  display: list-item;
  list-style-type: disc;
  color: rgba(var(--light-text), 1);
}
.earning-list li .earning-box .earning-content span.success {
  color: rgba(var(--success-color), 1);
}
.earning-list li .earning-box .earning-content span.reject {
  color: rgba(var(--error-color), 1);
}
.earning-list li .earning-details {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid rgba(var(--black), 0.05);
  border-radius: 8px;
}
.earning-list li .earning-details .earning-details-list {
  position: relative;
}
.earning-list li .earning-details .earning-details-list::after {
  content: "";
  position: absolute;
  border-bottom: 1px dashed rgb(214, 214, 214);
  width: 100%;
  bottom: 4px;
  left: 0;
}
[dir=rtl] .earning-list li .earning-details .earning-details-list::after {
  left: unset;
  right: 0;
}
.earning-list li .earning-details .earning-details-list h6 {
  background-color: rgba(var(--box-bg), 1);
  z-index: 1;
}
.earning-list li .payment-box {
  margin-top: 12px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(var(--box-bg), 1);
  border-radius: 6px;
}
.earning-list li .payment-box .icon {
  width: 20px;
  height: 20px;
  filter: invert(1) grayscale(1);
}
.earning-list li .payment-box h6 {
  padding-left: 10px;
  border-left: 1px solid rgba(var(--dashed-line), 1);
}
[dir=rtl] .earning-list li .payment-box h6 {
  padding-left: unset;
  padding-right: 10px;
  border-left: unset;
  border-right: 1px solid rgba(var(--dashed-line), 1);
}
.earning-list li .payment-box.order-person-details {
  padding: 12px 0 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  background-color: rgba(var(--white), 1);
  border-top: 1px solid rgba(var(--dashed-line), 1);
  border-radius: 0;
}
.earning-list li .payment-box.order-person-details h6 {
  padding-left: 10px;
  border-left: 1px solid rgba(var(--dashed-line), 1);
}
[dir=rtl] .earning-list li .payment-box.order-person-details h6 {
  padding-left: unset;
  padding-right: 10px;
  border-left: unset;
  border-right: 1px solid rgba(var(--dashed-line), 1);
}
.earning-list li .payment-box.order-payment-details {
  padding: 10px 0 0 0;
  background-color: rgba(var(--white), 1);
  border-top: 1px solid rgba(var(--dashed-line), 1);
  border-radius: 0;
}
.earning-list li .payment-box.order-payment-details .box-content {
  width: 100%;
  border-right: 1px solid rgba(var(--dashed-line), 1);
}
[dir=rtl] .earning-list li .payment-box.order-payment-details .box-content {
  border-right: unset;
  border-left: 1px solid rgba(var(--dashed-line), 1);
}
.earning-list li .rider-list {
  margin-top: 10px;
  width: 100%;
  padding: 12px;
  display: flex;
  background-color: rgba(var(--box-bg), 1);
}
.earning-list li .rider-list li {
  font-size: 12px;
  margin: 0;
  padding-left: 10px;
  background-color: transparent;
  border-left: 1px solid rgba(var(--dashed-line), 1);
  border-top: none;
  border-right: none;
  border-bottom: none;
  box-shadow: none;
  border-radius: 0;
}
.earning-list li .rider-list li [dir=rtl] {
  padding-right: 10px;
  padding-left: 0;
  border-left: unset;
  border-right: 1px solid rgba(var(--dashed-line), 1);
}
.earning-list li .rider-list li:first-child {
  padding-left: 0;
  border-left: none;
}
[dir=rtl] .earning-list li .rider-list li:first-child {
  padding-right: 0;
  padding-left: unset;
  border-left: unset;
  border-right: none;
}
.earning-list li .rider-list li span {
  display: block;
}
.earning-list li .order-date {
  margin-top: 10px;
  padding: 15px;
  background-color: rgba(var(--box-bg), 1);
  border-radius: 4px;
}
.earning-list li .order-date h6 {
  font-weight: 500;
  font-size: 13px;
  color: rgba(var(--light-text), 1);
}

.status-box {
  padding: 20px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(42, 193, 93, 0.1);
  border-radius: 6px;
}
.status-box h5 {
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
}
.status-box h6 {
  margin-top: 8px;
  font-size: 400;
  color: rgba(var(--success-color), 1);
}
.status-box .switch-btn input[type=checkbox] {
  position: relative;
  width: 37px;
  height: 22px;
  appearance: none;
  background: rgba(var(--light-text), 0.3);
  outline: none;
  border-radius: 50px;
  cursor: pointer;
}
.status-box .switch-btn input[type=checkbox]:checked {
  background: rgba(var(--success-color), 0.3);
}
.status-box .switch-btn input[type=checkbox]:checked::before {
  transform: translateX(80%) translateY(-50%);
  background: rgba(var(--success-color), 1);
}
.status-box .switch-btn input[type=checkbox]:before {
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: rgba(var(--light-text), 1);
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  transition: 0.5s;
}
.status-box h3 {
  color: rgba(var(--dark-text), 1);
}
.status-box i {
  color: rgba(var(--dark-text), 1);
  font-size: 28px;
  line-height: 1;
}

/*=============================
    Restaurant-details CSS start
===============================*/
.restaurant-details-box {
  position: relative;
  background: rgba(var(--white), 1);
  border: 1px solid rgba(var(--black), 0.05);
  box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.06);
  border-radius: 14px;
  padding: 0 12px;
  margin-top: -80px;
  z-index: 1;
}
.restaurant-details-box .restaurant-head {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed rgba(var(--dashed-line), 1);
  padding: 12px 0;
}
.restaurant-details-box .restaurant-head .name {
  display: flex;
}
.restaurant-details-box .restaurant-head .name .restaurant-logo {
  width: 40px;
  height: 40px;
  border-radius: 8px;
}
.restaurant-details-box .restaurant-head .name h3 {
  font-weight: 700;
  color: rgba(var(--dark-text), 1);
}
.restaurant-details-box .restaurant-head .name h6 {
  margin-top: 5px;
  color: rgba(var(--light-text), 1);
}
.restaurant-details-box .restaurant-head .option {
  display: flex;
  gap: 15px;
  font-size: 20px;
}
.restaurant-details-box .restaurant-head .option .share {
  color: rgba(var(--dark-text), 1);
}
.restaurant-details-box .restaurant-head .option .heart {
  color: rgba(var(--error-color), 1);
}
.restaurant-details-box .restaurant-details {
  display: flex;
  align-items: center;
  padding: 12px 0;
}
.restaurant-details-box .restaurant-details .location {
  width: 60%;
  border-right: 1px solid rgba(var(--dashed-line), 1);
  margin-right: calc(10px + 10 * (100vw - 320px) / 1600);
  padding-right: calc(10px + 10 * (100vw - 320px) / 1600);
}
[dir=rtl] .restaurant-details-box .restaurant-details .location {
  border-right: unset;
  margin-right: unset;
  padding-right: unset;
  border-left: 1px solid rgba(var(--dashed-line), 1);
  margin-left: calc(10px + 10 * (100vw - 320px) / 1600);
  padding-left: calc(10px + 10 * (100vw - 320px) / 1600);
}
.restaurant-details-box .restaurant-details .location .rating-star {
  border-bottom: 1px solid rgba(var(--dashed-line), 1);
  padding-bottom: 8px;
  display: flex;
  gap: 5px;
  color: rgba(var(--dark-text), 1);
}
.restaurant-details-box .restaurant-details .location .rating-star .star {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: rgba(var(--success-color), 1);
  border-radius: 100%;
}
.restaurant-details-box .restaurant-details .location .rating-star .star i {
  color: rgb(255, 255, 255);
}
.restaurant-details-box .restaurant-details .distance {
  width: 40%;
  position: relative;
  z-index: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}
.restaurant-details-box .restaurant-details .distance::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 6px;
  width: 1px;
  height: 30%;
  border-left: 1px dashed rgba(var(--theme-color), 1);
  transform: translateY(-50%);
  z-index: -1;
}
[dir=rtl] .restaurant-details-box .restaurant-details .distance::after {
  left: unset;
  right: 6px;
  border-left: unset;
  border-right: 1px dashed rgba(var(--theme-color), 1);
}
.restaurant-details-box .restaurant-details .distance li {
  width: 100%;
  display: flex;
  gap: 5px;
  color: rgba(var(--dark-text), 1);
}
[dir=rtl] .restaurant-details-box .restaurant-details .distance li i {
  float: right;
}
.restaurant-details-box.restaurant-name-box {
  margin: -40px auto 0;
  width: 90%;
}
.restaurant-details-box.restaurant-name-box .name {
  display: flex;
  align-items: center;
  gap: 8px;
}
.restaurant-details-box.restaurant-name-box .rating-star {
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  color: rgba(var(--dark-text), 1);
}
.restaurant-details-box.restaurant-name-box .rating-star .star {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: rgba(var(--success-color), 1);
  border-radius: 100%;
}
.restaurant-details-box.restaurant-name-box .rating-star .star i {
  color: rgb(255, 255, 255);
}

.offer-box {
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px;
  gap: calc(10px + 10 * (100vw - 320px) / 1600);
  background: rgba(var(--box-bg), 1);
  border-radius: 7px;
}
.offer-box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 18%;
  width: 16px;
  height: 16px;
  background-color: rgba(var(--white), 1);
  border-radius: 100%;
  transform: translateY(-50%);
}
[dir=rtl] .offer-box::after {
  left: unset;
  right: 18%;
}
.offer-box::before {
  content: "";
  position: absolute;
  bottom: -16px;
  left: 18%;
  width: 16px;
  height: 16px;
  background-color: rgba(var(--white), 1);
  border-radius: 100%;
  transform: translateY(-50%);
}
[dir=rtl] .offer-box::before {
  left: unset;
  right: 18%;
}
.offer-box .offer-icon .offer {
  width: 40px;
  height: 40px;
}
.offer-box .offer-content {
  width: calc(100% - 40px - 20px);
}
.offer-box .offer-content .switch-btn input[type=checkbox] {
  position: relative;
  width: 37px;
  height: 22px;
  appearance: none;
  background: rgba(var(--light-text), 0.3);
  outline: none;
  border-radius: 50px;
  cursor: pointer;
}
.offer-box .offer-content .switch-btn input[type=checkbox]:checked {
  background: rgba(var(--theme-color), 0.3);
}
.offer-box .offer-content .switch-btn input[type=checkbox]:checked::before {
  transform: translateX(80%) translateY(-50%);
  background: rgba(var(--theme-color), 1);
}
.offer-box .offer-content .switch-btn input[type=checkbox]:before {
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: rgba(var(--light-text), 1);
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  transition: 0.5s;
}
.offer-box .offer-content h5 {
  color: rgba(var(--dark-text), 1);
  font-size: 13px;
}
.offer-box .offer-content h6 {
  margin-top: 5px;
}

.food-filter .food-symbol {
  display: flex;
  gap: 15px;
  border-bottom: 1px solid rgba(var(--black), 0.05);
  padding-bottom: 20px;
}
.food-filter .food-symbol .food-types {
  display: flex;
  border: 1px solid rgba(var(--box-bg), 1);
  padding: 5px;
  align-items: center;
  gap: 5px;
  border-radius: 4px;
  height: 100%;
}
.food-filter .food-symbol .food-types .img {
  width: 15px;
  height: 15px;
}
.food-filter .food-symbol .food-types h6 {
  color: rgba(var(--dark-text), 1);
  line-height: 1;
}
.food-filter .food-symbol .food-types .close {
  color: rgba(var(--dark-text), 1);
  display: none;
}
.food-filter .food-symbol .food-types .close.active {
  display: flex;
  color: rgba(var(--theme-color), 1);
}
.food-filter .food-symbol .food-types.active {
  display: flex;
  border: 1px solid rgba(var(--theme-color), 1);
}
.food-filter .food-symbol .food-types.active .close {
  display: flex;
  color: rgba(var(--theme-color), 1);
}

/*=====================
    Empty Tab CSS start
==========================*/
.empty-tab {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.empty-tab h2 {
  margin-top: 40px;
  color: rgba(var(--dark-text), 1);
}
.empty-tab h5 {
  color: rgba(var(--light-text), 1);
  line-height: 1.3;
  font-weight: 400;
}
.empty-tab p {
  margin-top: calc(10px + 10 * (100vw - 320px) / 1600);
  line-height: 1.5;
  font-size: 13px;
  font-weight: 400;
  color: rgba(var(--light-text), 1);
}
.empty-tab .empty-btn {
  margin-top: 0;
  display: block;
  padding: 15px !important;
  width: 100% !important;
}
.empty-tab .try-btn {
  padding: 10px 30px !important;
}
.empty-tab .empty-bell {
  margin-top: 100px;
}
.empty-tab .empty-cart {
  margin-top: 70px;
}
.empty-tab .empty-search {
  margin-top: 90px;
}
.empty-tab .empty-wishlist {
  margin-top: 70px;
}

.grocery-categories-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 12px;
  color: rgba(var(--light-text), 1);
  background-color: rgba(var(--box-bg), 1);
  border-radius: 6px;
}
[dir=rtl] .grocery-categories-box {
  direction: rtl;
}
.grocery-categories-box.active {
  color: rgb(255, 255, 255);
  background-color: rgba(var(--theme-color), 1);
}
.grocery-categories-box.active h6 {
  border-left: 1px solid rgb(255, 255, 255);
}
[dir=rtl] .grocery-categories-box.active h6 {
  border-right: 1px solid rgb(255, 255, 255);
  border-left: unset;
}
.grocery-categories-box h6 {
  padding-left: 8px;
  border-left: 1px solid rgba(var(--light-text), 0.4);
}
[dir=rtl] .grocery-categories-box h6 {
  padding-right: 8px;
  padding-left: unset;
  border-right: 1px solid rgba(var(--light-text), 0.4);
  border-left: unset;
}

.grocery-banner-img {
  border-radius: 6px;
}

.grocery-product-box {
  position: relative;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid rgb(var(--box-bg), 1);
  background: rgba(var(--white), 1);
  box-shadow: 0px 2px 15px 0px rgba(var(--black), 0.04);
}
[dir=rtl] .grocery-product-box {
  direction: rtl;
}
.grocery-product-box .offer-tag {
  position: absolute;
  top: 12px;
  left: 12px;
  padding: 5px 10px;
  color: rgb(var(--error-color), 1);
  background-color: rgb(var(--error-color), 0.15);
  border-radius: 10px;
}
[dir=rtl] .grocery-product-box .offer-tag {
  right: 12px;
  left: unset;
}
.grocery-product-box .grocery-product-img {
  padding: 48px 0 18px;
  text-align: center;
}
.grocery-product-box .grocery-product-img .img {
  width: 90px;
  height: 70px;
  object-fit: contain;
}
.grocery-product-box .grocery-product-details h4 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.grocery-product-box .grocery-product-details .price {
  font-size: 13px;
}
.grocery-product-box .like-icon {
  position: absolute;
  top: 5px;
  right: 5px;
}
[dir=rtl] .grocery-product-box .like-icon {
  right: unset;
  left: 5px;
}
.grocery-product-box .like-icon i {
  padding: 5px;
  color: rgba(var(--error-color), 1);
  background-color: rgba(var(--white), 1);
  border-radius: 100%;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.grocery-product-box .like-icon .fill-icon {
  display: none;
}
.grocery-product-box .like-icon.active .outline-icon {
  display: none;
}
.grocery-product-box .like-icon.active .fill-icon {
  display: flex;
  color: rgba(var(--error-color), 1);
}
.grocery-product-box .like-icon.active .effect:before {
  animation: fireworkLine 0.5s linear 0.1s;
}
.grocery-product-box .like-icon.active .effect:after {
  animation: fireworkPoint 0.5s linear 0.1s;
}
.grocery-product-box .effect-group {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.grocery-product-box .effect-group .effect {
  display: block;
  position: absolute;
  top: 38%;
  left: 50%;
  width: 20px;
  transform-origin: 0px 2px;
}
.grocery-product-box .effect-group .effect:nth-child(2) {
  transform: rotate(72deg);
}
.grocery-product-box .effect-group .effect:nth-child(3) {
  transform: rotate(144deg);
}
.grocery-product-box .effect-group .effect:nth-child(4) {
  transform: rotate(216deg);
}
.grocery-product-box .effect-group .effect:nth-child(5) {
  transform: rotate(288deg);
}
.grocery-product-box .effect-group .effect:before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  border-radius: 2px;
  height: 3px;
  background: #777777;
}
.grocery-product-box .effect-group .effect:after {
  content: "";
  display: block;
  position: absolute;
  top: 10px;
  right: 10%;
  border-radius: 50%;
  width: 3px;
  height: 3px;
  background: rgba(var(--theme-color), 1);
  transform: scale(0, 0);
}

.grocery-horizontal-product-box {
  border-radius: 4px;
  border: 1px solid rgba(var(--box-bg), 1);
  background: rgba(var(--white), 1);
  box-shadow: 0px 2px 15px 0px rgba(var(--white), 0.04);
  padding: 12px;
}
.grocery-horizontal-product-box li {
  padding: 20px 0;
  display: flex;
  align-items: center;
  gap: 6px;
  border-bottom: 1px solid rgba(var(--box-bg), 1);
}
.grocery-horizontal-product-box li:first-child {
  padding-top: 0;
}
.grocery-horizontal-product-box li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.grocery-horizontal-product-box li .horizontal-product-img .img {
  width: 64px;
  height: 64px;
}
.grocery-horizontal-product-box li .horizontal-product-details {
  position: relative;
  width: calc(100% - 70px);
}
.grocery-horizontal-product-box li .horizontal-product-details .add-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 5px calc(10px + 5 * (100vw - 320px) / 1600);
  font-size: 13px;
  color: rgba(var(--theme-color), 1);
  background-color: rgba(var(--theme-color), 0.1);
  border-radius: 5px;
}
[dir=rtl] .grocery-horizontal-product-box li .horizontal-product-details .add-btn {
  right: unset;
  left: 0;
}

.grocery-horizontal-product-box2 {
  border-radius: 4px;
  border: 1px solid rgba(var(--box-bg), 1);
  background: rgba(var(--white), 1);
  box-shadow: 0px 2px 15px 0px rgba(var(--black), 0.04);
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
}
.grocery-horizontal-product-box2 .horizontal-product-img .img {
  background-color: rgba(var(--box-bg), 1);
  width: 64px;
  height: 64px;
  padding: 8px;
}
.grocery-horizontal-product-box2 .horizontal-product-details {
  position: relative;
  width: calc(100% - 70px);
}
.grocery-horizontal-product-box2 .horizontal-product-details h5 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.grocery-horizontal-product-box2 .horizontal-product-details h6 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1;
}
.grocery-horizontal-product-box2 .horizontal-product-details .add-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 5px calc(10px + 5 * (100vw - 320px) / 1600);
  font-size: 13px;
  color: rgba(var(--theme-color), 1);
  background-color: rgba(var(--theme-color), 0.1);
  border-radius: 5px;
}
[dir=rtl] .grocery-horizontal-product-box2 .horizontal-product-details .add-btn {
  right: unset;
  left: 0;
}

/*=====================
    home Page CSS
==========================*/
.search-section .form-group {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
}
.search-section .form-group .form-input {
  position: relative;
  width: calc(100% - 40px - 10px);
}
.search-section .form-group .form-input .search-icon {
  font-size: 20px;
}
.search-section .form-group .form-input .search-icon::after {
  display: none !important;
}
.search-section .form-group .form-input i {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  color: rgba(var(--light-text), 1);
  padding-right: 10px;
  font-size: 14px;
}
[dir=rtl] .search-section .form-group .form-input i {
  left: unset;
  right: 12px;
  padding-right: unset;
  padding-left: 10px;
}
.search-section .form-group .filter-button {
  padding: 0;
  background-color: rgba(var(--box-bg), 1);
  backdrop-filter: blur(2px);
  border-radius: 6px;
  border: none;
  color: rgba(var(--white), 1);
  width: auto;
  height: auto;
  padding: 15px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-section .form-group .filter-button i {
  color: rgb(132, 132, 133);
  font-size: 15px;
}
.search-section .form-group .filter-button:focus {
  box-shadow: none;
}

.search-form .form-group .form-input .form-control {
  padding: 8px 8px 8px 45px;
}
[dir=rtl] .search-form .form-group .form-input .form-control {
  padding: 8px 45px 8px 8px;
}
.search-form .form-group .form-input .form-control:focus {
  box-shadow: none;
}
.search-form .form-group .form-input i::after {
  content: none;
}

.brands-logo .food-brands {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 100%;
  margin: 0 auto;
}
.brands-logo h4 {
  margin-top: 10px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: rgba(var(--dark-text), 1);
}

.empty-section h2 {
  width: 90%;
  font-size: 45px;
  font-weight: 800;
  color: rgba(var(--dark-text), 0.5);
}
.empty-section h5 {
  margin: 15px 0;
  color: rgba(var(--light-text), 1);
  text-align: left;
}
[dir=rtl] .empty-section h5 {
  text-align: right;
}

.banner-section .banner-img {
  width: 100%;
}

/*=====================
    onboarding Page CSS
==========================*/
.home {
  position: relative;
}
.home .cooking-video {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 767px;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  object-fit: cover;
}
.home .theme-content {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 767px;
  padding: 20px 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.89) 57.48%, rgba(0, 0, 0, 0) 100%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.home .slogan {
  margin-top: 20px;
  padding: 12px 0;
  border-top: 1px solid rgba(225, 225, 225, 0.12);
  border-bottom: 1px solid rgba(225, 225, 225, 0.12);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home .slogan li {
  color: rgb(225, 225, 225);
  font-size: 20px;
  font-weight: 600;
  display: flex;
  gap: calc(10px + 10 * (100vw - 320px) / 1600);
}
.home p {
  margin-top: 15px;
  font-size: 20px;
  font-weight: 600;
  color: rgb(225, 225, 225);
  padding: 0;
}
.home .start-btn:active {
  background-color: rgba(var(--theme-color), 1);
  color: rgba(var(--white), 1);
}

/*=====================
    landing Page CSS
==========================*/
.main-categories-box {
  padding: calc(10px + 5 * (100vw - 320px) / 1600);
  border: 1px solid rgba(var(--box-bg), 1);
  border-radius: 5px;
}
.main-categories-box .main-categories-img {
  text-align: center;
}
.main-categories-box .main-categories-img .img {
  width: 100%;
  height: 40px;
  object-fit: contain;
  padding-bottom: 10px;
}
.main-categories-box h6 {
  border-top: 1px solid rgba(var(--box-bg), 1);
  padding-top: 10px;
  text-align: center;
}

.offer-deal-box {
  background-color: rgba(var(--box-bg), 1);
  padding: calc(10px + 5 * (100vw - 320px) / 1600);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: calc(5px + 5 * (100vw - 320px) / 1600);
}
.offer-deal-box .deal-img {
  height: 25px;
  width: 25px;
}
.offer-deal-box h5 {
  font-size: calc(13px + 1 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 1);
}

.main-banner-box {
  cursor: pointer;
}
.main-banner-box .banner-img {
  border-radius: 12px 12px 0 0;
}
.main-banner-box .main-banner-details {
  margin-top: 4px;
  padding: 12px;
  border-radius: 0 0 12px 12px;
  border: 1px solid rgba(var(--box-bg), 1);
}
.main-banner-box .main-banner-details h3 {
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
}
.main-banner-box .main-banner-details h5 {
  margin-top: 4px;
  font-size: 500;
  color: rgba(var(--light-text), 1);
  padding-bottom: 12px;
}
.main-banner-box .main-banner-details h5 span {
  color: rgba(var(--theme-color), 1);
}
.main-banner-box .main-banner-details .order-part {
  padding-top: 12px;
  border-top: 1px dashed rgba(var(--light-text), 0.3);
}
.main-banner-box .main-banner-details .order-part h5 {
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
}

.main-product-card {
  border-radius: 6px;
  border: 1px solid rgba(var(--box-bg), 1);
  box-shadow: 0px 2px 15px 0px rgba(var(--black), 0.04);
}
[dir=rtl] .main-product-card {
  direction: rtl;
}
.main-product-card .product-card-img {
  border-radius: 6px 6px 0 0;
}
.main-product-card .card-body {
  padding: 12px;
}
.main-product-card .card-body .card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main-product-card .card-body .add-btn {
  padding: 5px 15px;
}

/*=============================
    Location CSS start
===============================*/
.location-map {
  border: 0;
  height: 100vh;
  width: 100%;
}

.delivery-root {
  position: relative;
  border: 0;
  height: 100vh;
  width: 100%;
  margin-bottom: -16px;
}
.delivery-root > div {
  height: 100%;
}

.location-popup {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  max-width: 600px;
  background: linear-gradient(180deg, #414449 1.31%, #1f1f1f 101.06%);
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.14);
  border-radius: 20px 20px 0px 0px;
  height: auto;
  padding: 20px;
}
.location-popup .change-location {
  display: flex;
  justify-content: space-between;
}
.location-popup .change-location h2 {
  font-weight: 500;
  color: rgb(255, 255, 255);
}
.location-popup .change-location i {
  color: rgba(var(--theme-color), 1);
  font-size: 18px;
}
.location-popup .address {
  font-size: 14px;
  font-weight: 500;
}
.location-popup .delivery-btn {
  font-size: 16px;
}

/*=====================
    Notification CSS start
==========================*/
.notification .notification-box {
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 20px;
}
.notification .notification-box .notification-icon {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 1px dashed rgba(var(--light-text), 1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.notification .notification-box .notification-icon::before {
  content: "";
  position: absolute;
  left: 50px;
  top: 21px;
  width: 18px;
  height: 1px;
  background-color: rgba(var(--light-text), 1);
}
[dir=rtl] .notification .notification-box .notification-icon::before {
  left: unset;
  right: 50px;
}
.notification .notification-box .notification-icon::after {
  content: "";
  position: absolute;
  left: 48px;
  top: 36px;
  width: 20px;
  height: 1px;
  background-color: rgba(var(--light-text), 1);
}
[dir=rtl] .notification .notification-box .notification-icon::after {
  left: unset;
  right: 48px;
}
.notification .notification-box .notification-icon span {
  width: 42px;
  height: 42px;
  background-color: rgba(var(--box-bg), 1);
  padding: 15px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notification .notification-box .notification-icon span::before {
  content: "";
  position: absolute;
  left: 70px;
  top: 19px;
  transform: translate(-50%);
  width: 5px;
  height: 5px;
  background-color: rgba(var(--light-text), 1);
  border-radius: 100%;
}
[dir=rtl] .notification .notification-box .notification-icon span::before {
  left: unset;
  right: 70px;
}
.notification .notification-box .notification-icon span::after {
  content: "";
  position: absolute;
  left: 70px;
  top: 34px;
  transform: translate(-50%);
  width: 5px;
  height: 5px;
  background-color: rgba(var(--light-text), 1);
  border-radius: 100%;
}
[dir=rtl] .notification .notification-box .notification-icon span::after {
  left: unset;
  right: 70px;
}
.notification .notification-box .notification-icon span .notification-img {
  stroke: rgba(var(--light-text), 1);
  width: 21px;
  height: 21px;
  border-radius: 100%;
}
.notification .notification-box .notification-icon span .notification-img.img1 {
  padding: 5px;
}
.notification .notification-box.unread .notification-icon {
  border: 1px dashed rgba(var(--theme-color), 1);
}
.notification .notification-box.unread .notification-icon::before {
  background-color: rgba(var(--theme-color), 1);
}
.notification .notification-box.unread .notification-icon::after {
  background-color: rgba(var(--theme-color), 1);
}
.notification .notification-box.unread .notification-icon span {
  background-color: rgba(var(--theme-color), 1);
}
.notification .notification-box.unread .notification-icon span::before {
  background-color: rgba(var(--theme-color), 1);
}
.notification .notification-box.unread .notification-icon span::after {
  background-color: rgba(var(--theme-color), 1);
}
.notification .notification-box.unread .notification-icon span .notification-img {
  border: 1px solid rgb(255, 255, 255);
}
.notification .notification-box.unread .notification-content p {
  color: rgba(var(--dark-text), 1);
}
.notification .notification-box.unread .notification-content h5 {
  font-weight: 600;
}
.notification .notification-box .notification-content {
  width: calc(100% - 60px);
  margin-left: 10px;
  background: rgba(var(--white), 1);
  border: 1px solid rgba(var(--black), 0.05);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 12px 12px 12px 18px;
}
[dir=rtl] .notification .notification-box .notification-content {
  margin-left: unset;
  margin-right: 10px;
  padding: 12px 18px 12px 12px;
}
.notification .notification-box .notification-content h5 {
  color: rgba(var(--dark-text), 1);
  font-size: 14px;
  line-height: 1.2;
}
.notification .notification-box .notification-content h6 {
  color: rgba(var(--light-text), 1);
}
.notification .notification-box .notification-content p {
  color: rgba(var(--light-text), 1);
  border-top: 1px dashed rgba(var(--dashed-line), 1);
  padding: 8px 0 0 0;
  margin: 8px 0 0 0;
}

.notification-setting {
  background: rgba(var(--white), 1);
  border: 1px solid rgba(var(--black), 0.05);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 15px;
}
.notification-setting li {
  display: block;
  border-bottom: 1px solid rgba(var(--dashed-line), 0.5);
}
.notification-setting li:last-child {
  border-bottom: none;
}
.notification-setting li .notification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px 0;
}
.notification-setting li .notification input[type=checkbox] {
  position: relative;
  width: 37px;
  height: 22px;
  appearance: none;
  background: rgba(var(--box-bg), 1);
  outline: none;
  border-radius: 50px;
  cursor: pointer;
}
.notification-setting li .notification input[type=checkbox]:checked {
  background: rgba(var(--theme-color), 0.1);
}
.notification-setting li .notification input[type=checkbox]:checked::before {
  transform: translateX(80%) translateY(-50%);
  background: rgba(var(--theme-color), 1);
}
.notification-setting li .notification input[type=checkbox]:before {
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: rgba(var(--light-text), 1);
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  transition: 0.5s;
}
.notification-setting.status-items {
  border-bottom: 1px dashed rgba(var(--dashed-line), 1);
}

/*=============================
    order CSS start
===============================*/
.order-tracking {
  background-color: rgba(var(--white), 1);
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: calc(15px + 5 * (100vw - 320px) / 1600);
}
.order-tracking .tracking-place li {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 25px;
  width: 100%;
}
.order-tracking .tracking-place li:last-child::after {
  display: none;
}
.order-tracking .tracking-place li::after {
  content: "";
  position: absolute;
  bottom: 10px;
  left: 70px;
  width: 25px;
  border: 1px dashed rgba(var(--light-text), 1);
  transform: rotate(90deg);
}
[dir=rtl] .order-tracking .tracking-place li::after {
  left: unset;
  right: 70px;
}
.order-tracking .tracking-place li h6 {
  color: rgba(var(--dark-text), 1);
}
.order-tracking .tracking-place li h6.color-1 {
  color: rgb(255, 185, 49);
}
.order-tracking .tracking-place li h6.color-2 {
  color: rgb(68, 111, 221);
}
.order-tracking .tracking-place li h6.color-3 {
  color: rgba(var(--error-color), 1);
}
.order-tracking .tracking-place li h6.color-4 {
  color: rgba(var(--success-color), 1);
}
.order-tracking .tracking-place li span {
  position: relative;
  width: 25px;
  height: 25px;
  background-color: rgba(var(--box-bg), 1);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.order-tracking .tracking-place li span::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: rgba(var(--light-text), 1);
  border-radius: 100%;
}
.order-tracking .tracking-place li .icon {
  padding: 6px;
  border-radius: 6px;
}
.order-tracking .tracking-place li .icon.step-1 {
  background: rgb(255, 241, 214);
}
.order-tracking .tracking-place li .icon.step-2 {
  background: rgb(225, 232, 250);
}
.order-tracking .tracking-place li .icon.step-3 {
  background: rgb(252, 217, 217);
}
.order-tracking .tracking-place li .icon.step-4 {
  background: rgb(224, 239, 217);
}
.order-tracking .tracking-place li.active::after {
  border-color: rgba(var(--theme-color), 1);
}
.order-tracking .tracking-place li.active span {
  background-color: rgba(var(--theme-color), 0.15);
}
.order-tracking .tracking-place li.active span::after {
  background-color: rgba(var(--theme-color), 1);
}

/*=============================
     Page-listing CSS start
===============================*/
.categories-title {
  margin-top: 20px;
}
.categories-title h2 {
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
  background-color: rgba(var(--box-bg), 1);
  padding: 15px;
  border-radius: 10px;
}

.categories-menu .navigation .pages {
  width: 100%;
  padding: 0 10px;
}
.categories-menu .navigation .pages h4 {
  font-size: 15px;
}
.categories-menu .navigation .pages a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(var(--dark-text), 1);
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  font-weight: 500;
  width: 100%;
}
.categories-menu .navigation .pages a i {
  color: rgba(var(--dark-text), 1);
  font-size: 30px;
  line-height: 1;
}
[dir=rtl] .categories-menu .navigation .pages a i {
  transform: scaleX(-1);
}

/*=============================
    payment CSS start
===============================*/
.payment-list .payment-card-box {
  flex-wrap: wrap;
  padding: 0 15px;
  border-radius: 14px;
}
.payment-list .payment-card-box .payment-detail {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
  padding: 15px 0;
}
.payment-list .payment-card-box .payment-detail .add-img {
  width: 35px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.payment-list .payment-card-box .payment-detail .add-img .img {
  width: 35px;
  height: 30px;
}
.payment-list .payment-card-box .payment-detail .add-img .add-square-img {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  border: 1.5px solid;
  border-radius: 10px;
  width: 100%;
}
.payment-list .payment-card-box .payment-detail .add-content {
  width: calc(100% - 35px - 15px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.payment-list .payment-card-box .payment-detail .add-content h5 {
  color: rgba(var(--dark-text), 1);
}
.payment-list .payment-card-box .payment-detail .add-content .form-check .form-check-input {
  box-shadow: none;
}
.payment-list .payment-card-box .payment-detail .add-content .form-check .form-check-input:checked {
  background-color: rgba(var(--theme-color), 1);
  border-color: rgba(var(--theme-color), 1);
}
.payment-list .new-card {
  border-top: 1px dashed rgba(var(--dashed-line), 1);
}

.debit-card {
  position: relative;
  background-image: url(../images/background/card.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.14);
  border-radius: 14px;
}
.debit-card .card-details {
  padding: 22px;
}
.debit-card .card-details .card-name {
  color: rgba(225, 225, 225, 0.5);
}
.debit-card .card-details .chip {
  margin-top: 12px;
}
.debit-card .card-details .ac-details {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.debit-card .card-details .ac-details li h6 {
  font-weight: 400;
  color: rgba(225, 225, 225, 0.5);
}
.debit-card .card-details .ac-details li h3 {
  font-weight: 700;
  color: rgb(225, 225, 225);
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}
.debit-card .card-details .user-name {
  margin-top: 15px;
}
.debit-card .card-details .user-name h6 {
  font-weight: 400;
  color: rgba(225, 225, 225, 0.5);
}
.debit-card .card-details .user-name h5 {
  font-weight: 500;
  color: rgb(225, 225, 225);
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.address-form {
  border-top: 1px dashed rgba(var(--dashed-line), 1);
  padding-top: 20px;
}

.pharmacy-categories-box {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(var(--light-text), 1);
  background-color: rgba(var(--box-bg), 1);
  border-radius: 100%;
  margin: 0 auto;
}
.pharmacy-categories-box.active {
  color: rgba(var(--white), 1);
  background-color: rgba(var(--theme-color), 0.1);
}
.pharmacy-categories-box.active h6 {
  border-left: 1px solid rgba(var(--white), 1);
}

.pharmacy-banner-img {
  border-radius: 6px;
}

.pharmacy-product-box {
  padding: 0;
  position: relative;
}
.pharmacy-product-box .ratings {
  position: absolute;
  top: 15px;
  right: 12px;
}
[dir=rtl] .pharmacy-product-box .ratings {
  left: 12px;
  right: unset;
}
.pharmacy-product-box .grocery-product-details {
  background-color: rgba(var(--box-bg), 1);
  padding: 8px 12px;
}
.pharmacy-product-box .grocery-product-details .cart-btn {
  background-color: rgba(var(--theme-color), 1);
  padding: 5px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}

.pharmacy-horizontal-product-box {
  background-color: rgba(var(--box-bg), 1);
}
.pharmacy-horizontal-product-box li {
  border-bottom: 1px dashed rgba(var(--black), 0.06);
  gap: 12px;
}
.pharmacy-horizontal-product-box li .horizontal-product-img {
  padding: 8px;
  background-color: rgba(var(--white), 1);
  border-radius: 4px;
}
.pharmacy-horizontal-product-box li h5 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pharmacy-horizontal-product-box2 {
  border-radius: 4px;
  border: 1px solid rgba(var(--box-bg), 1);
  background: rgba(var(--white), 1);
  box-shadow: 0px 2px 15px 0px rgba(var(--black), 0.04);
  display: flex;
  align-items: unset;
  padding: 0;
  gap: 0;
  height: 100%;
}
[dir=rtl] .pharmacy-horizontal-product-box2 {
  direction: rtl;
}
.pharmacy-horizontal-product-box2 .horizontal-product-img .img {
  width: 80px;
  height: 100%;
}
.pharmacy-horizontal-product-box2 .horizontal-product-details {
  position: relative;
  width: calc(100% - 80px);
  padding: 12px;
}
.pharmacy-horizontal-product-box2 .horizontal-product-details .add-btn {
  right: 15px;
  bottom: 15px;
}
[dir=rtl] .pharmacy-horizontal-product-box2 .horizontal-product-details .add-btn {
  left: 15px;
  right: unset;
}

.discount-banner {
  border-radius: 6px;
}

/*=============================
    profile CSS start
===============================*/
.profile-cover {
  position: relative;
  width: 100%;
  height: 90px;
  background: linear-gradient(333.77deg, #ff8d2f 9.66%, #ffd3b0 101.81%);
  border-radius: 20px 20px 0px 0px;
}
.profile-cover .profile-pic {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translate(-50%);
  padding: 5px;
  width: 75px;
  height: 75px;
  border-radius: 100%;
  background-color: rgba(var(--white), 1);
}

.profile-name {
  margin-top: 25px;
}
.profile-name h5 {
  font-weight: 600;
  color: rgba(var(--dark-text), 1);
  text-align: center;
  margin-top: 30px;
}
.profile-name h6 {
  color: rgba(var(--dark-text), 1);
  text-align: center;
  font-weight: 400;
  margin-top: 4px;
}

.profile-list li {
  width: 100%;
  padding-bottom: 15px;
}
.profile-list li .profile-box {
  display: flex;
  align-items: center;
  gap: 15px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 100px 38px 38px 100px;
  color: rgba(var(--dark-text), 1);
  border: 1px solid rgba(var(--black), 0.05);
  background-color: rgba(var(--box-bg), 1);
}
[dir=rtl] .profile-list li .profile-box {
  border-radius: 38px 100px 100px 38px;
}
.profile-list li .profile-box .profile-icon {
  height: 32px;
  width: 32px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1.4);
  font-size: 22px;
  border: 1px solid rgba(var(--light-text), 0.2);
  background-color: rgba(var(--box-bg), 1);
}
.profile-list li .profile-box .profile-icon .icon {
  width: 16px;
  height: 16px;
}
.profile-list li .profile-box .profile-box-name {
  width: calc(100% - 40px - 15px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  margin-left: 6px;
  color: rgba(var(--dark-text), 1);
}
[dir=rtl] .profile-list li .profile-box .profile-box-name {
  margin-left: unset;
  margin-right: 6px;
}
.profile-list li .profile-box .profile-box-name h6 {
  font-weight: 500;
  font-size: 1px;
}
.profile-list li .profile-box .profile-box-name h6 .arrow {
  margin-left: auto;
  font-size: 14px;
}
[dir=rtl] .profile-list li .profile-box .profile-box-name h6 .arrow {
  margin-left: unset;
  margin-right: auto;
}
.profile-list li .profile-box .profile-box-name i {
  font-size: 16px;
}
[dir=rtl] .profile-list li .profile-box .profile-box-name i {
  transform: scaleX(-1);
  margin-left: 8px;
}

.profile-img {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  height: 100px;
}
.profile-img .img {
  border-radius: 18px;
}
.profile-img .camera {
  position: absolute;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(var(--white), 1);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  font-size: 20px;
  height: 32px;
  width: 32px;
  bottom: -5px;
  right: -10px;
}
[dir=rtl] .profile-img .camera {
  margin-left: -10px;
  margin-right: unset;
}

/*==========================
     restaurant-app CSS start
============================*/
.total-sales-background {
  width: 100%;
  padding: 15px;
  background-color: rgba(var(--theme-color), 0.1);
  border: 1px solid rgba(var(--theme-color), 0.3);
  border-radius: 6px;
}
.total-sales-background .earning-card-details .icon {
  padding-right: 8px;
  border-right: 1px solid rgba(var(--theme-color), 0.3);
}
[dir=rtl] .total-sales-background .earning-card-details .icon {
  padding-right: unset;
  padding-left: 8px;
  border-right: unset;
  border-left: 1px solid rgba(var(--theme-color), 0.3);
}
.total-sales-background .earning-card-details h3 {
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
}
.total-sales-background .earning-card-details {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(var(--theme-color), 0.3);
}
.total-sales-background .earning-card-details h3 {
  font-weight: 400;
  color: rgb(255, 255, 255);
}
.total-sales-background .earning-card-details h2 {
  font-weight: 600;
  color: rgba(var(--theme-color), 1);
}
.total-sales-background .sale-timing-list {
  padding-top: 15px;
  display: flex;
}
.total-sales-background .sale-timing-list li {
  padding-left: calc(10px + 15 * (100vw - 320px) / 1600);
  border-left: 1px solid rgba(var(--theme-color), 0.3);
}
[dir=rtl] .total-sales-background .sale-timing-list li {
  padding-left: unset;
  padding-right: calc(10px + 15 * (100vw - 320px) / 1600);
  border-left: unset;
  border-right: 1px solid rgba(var(--theme-color), 0.3);
}
.total-sales-background .sale-timing-list li:first-child {
  padding-left: 0;
  border-left: 0;
}
[dir=rtl] .total-sales-background .sale-timing-list li:first-child {
  padding-left: unset;
  padding-right: 0;
  border-right: 0;
  border-left: unset;
}

.coupon-list li {
  width: 100%;
  margin-bottom: 20px;
}
.coupon-list li:last-child {
  margin-bottom: 0;
}
.coupon-list li .offer-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  gap: calc(10px + 10 * (100vw - 320px) / 1600);
  background: rgba(var(--box-bg), 1);
  border-radius: 10px;
  cursor: pointer;
}
.coupon-list li .offer-box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 18%;
  width: 16px;
  height: 16px;
  background-color: rgba(var(--white), 1);
  border-radius: 100%;
  transform: translateY(-50%);
}
[dir=rtl] .coupon-list li .offer-box::after {
  left: unset;
  right: 18%;
}
.coupon-list li .offer-box::before {
  content: "";
  position: absolute;
  bottom: -16px;
  left: 18%;
  width: 16px;
  height: 16px;
  background-color: rgba(var(--white), 1);
  border-radius: 100%;
  transform: translateY(-50%);
}
[dir=rtl] .coupon-list li .offer-box::before {
  left: unset;
  right: 18%;
}
.coupon-list li .offer-box .offer-icon .offer {
  width: 40px;
  height: 40px;
}
.coupon-list li .offer-box .offer-content {
  width: calc(100% - 40px - 20px);
}
.coupon-list li .offer-box .offer-content .switch-btn {
  z-index: 2;
}
.coupon-list li .offer-box .offer-content .switch-btn input[type=checkbox] {
  position: relative;
  width: 37px;
  height: 22px;
  appearance: none;
  background: rgba(var(--light-text), 0.3);
  outline: none;
  border-radius: 50px;
  cursor: pointer;
}
.coupon-list li .offer-box .offer-content .switch-btn input[type=checkbox]:checked {
  background: rgba(var(--theme-color), 0.3);
}
.coupon-list li .offer-box .offer-content .switch-btn input[type=checkbox]:checked::before {
  transform: translateX(80%) translateY(-50%);
  background: rgba(var(--theme-color), 1);
}
.coupon-list li .offer-box .offer-content .switch-btn input[type=checkbox]:before {
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: rgba(var(--light-text), 1);
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  transition: 0.5s;
}
.coupon-list li .offer-box .offer-content h5 {
  color: rgba(var(--dark-text), 1);
  font-size: 13px;
}
.coupon-list li .offer-box .offer-content h6 {
  margin-top: 5px;
}

.restaurant-banner .cover-img {
  width: 100%;
  height: calc(110px + 110 * (100vw - 320px) / 1600);
  object-fit: cover;
  object-position: top center;
  border-radius: 10px;
}

.restaurant-info-box {
  padding: 20px;
  border: 1px solid rgba(var(--black), 0.05);
  background-color: rgba(var(--white), 1);
  border-radius: 10px;
  box-shadow: 0px 3.051px 18.309px 0px rgba(var(--black), 0.04);
}
.restaurant-info-box .details-list li {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 15px;
}
.restaurant-info-box .details-list li:nth-child(2) {
  border-bottom: 1px dashed rgba(var(--dashed-line), 1);
}
.restaurant-info-box .details-list li:nth-child(3) {
  padding-top: 15px;
}
.restaurant-info-box .details-list li:nth-child(7) {
  border-bottom: 1px dashed rgba(var(--dashed-line), 1);
}
.restaurant-info-box .details-list li:nth-child(8) {
  padding-top: 15px;
}
.restaurant-info-box .details-list li:last-child {
  padding-bottom: 0;
}
.restaurant-info-box .details-list li .heading1 {
  width: 40%;
  color: rgba(var(--dark-text), 1);
}
.restaurant-info-box .details-list li span {
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: rgba(var(--dark-text), 1);
}
.restaurant-info-box .details-list li .content {
  color: rgba(var(--dark-text), 1);
  padding-left: 15px;
}
[dir=rtl] .restaurant-info-box .details-list li .content {
  padding-right: 15px;
  padding-left: unset;
}

.item-detail-tab {
  padding-top: 30px;
  border-top: 1px dashed rgba(var(--dashed-line), 1);
}
.item-detail-tab .item-content-box {
  margin-top: 20px;
  padding: 20px;
  background-color: rgba(var(--white), 1);
  border: 1px solid rgba(var(--dashed-line), 1);
  border-radius: 10px;
  box-shadow: 0px 3px 18px 0px rgba(var(--black), 0.04);
}
.item-detail-tab .item-content-box .item-content-head {
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(var(--dashed-line), 1);
}
.item-detail-tab .item-content-box .item-content-head .box-content {
  width: 50%;
  border-right: 1px solid rgba(var(--dashed-line), 1);
}
[dir=rtl] .item-detail-tab .item-content-box .item-content-head .box-content {
  border-right: unset;
  border-left: 1px solid rgba(var(--dashed-line), 1);
}
.item-detail-tab .item-content-box .item-content-head .box-content h5 {
  font-weight: 600;
  color: rgba(var(--dark-text), 1);
}
.item-detail-tab .item-content-box .item-content-head .box-content h6 {
  margin-top: 10px;
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
}
.item-detail-tab .item-content-box .description-part {
  padding: 15px 0;
}
.item-detail-tab .item-content-box .description-part h5 {
  font-weight: 600;
  color: rgba(var(--dark-text), 1);
}
.item-detail-tab .item-content-box .description-part p {
  text-align: justify;
  margin-top: 5px;
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
}
.item-detail-tab .item-content-box .variety-part h5 {
  font-weight: 600;
  color: rgba(var(--dark-text), 1);
}
.item-detail-tab .item-content-box .variety-part .variety-list {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.item-detail-tab .item-content-box .variety-part .variety-list li {
  width: 100%;
  padding-inline: calc(10px + 15 * (100vw - 320px) / 1600);
  border-right: 1px solid rgba(var(--dashed-line), 1);
}
[dir=rtl] .item-detail-tab .item-content-box .variety-part .variety-list li {
  border-right: unset;
  border-left: 1px solid rgba(var(--dashed-line), 1);
}
.item-detail-tab .item-content-box .variety-part .variety-list li:first-child {
  padding-left: 0;
}
[dir=rtl] .item-detail-tab .item-content-box .variety-part .variety-list li:first-child {
  padding-left: unset;
  padding-right: 0;
}
.item-detail-tab .item-content-box .variety-part .variety-list li:last-child {
  padding-right: 0;
  border-right: none;
}
[dir=rtl] .item-detail-tab .item-content-box .variety-part .variety-list li:last-child {
  padding-right: unset;
  padding-left: 0;
  border-right: unset;
  border-left: none;
}

.review-list {
  margin-top: 20px;
  padding: 20px;
  background-color: rgba(var(--white), 1);
  border: 1px solid rgba(var(--dashed-line), 1);
  border-radius: 10px;
  box-shadow: 0px 3px 18px 0px rgba(var(--black), 0.04);
}
.review-list .review-box {
  padding-block: 15px;
  border-bottom: 1px dashed rgba(var(--dashed-line), 1);
}
.review-list .review-box:first-child {
  padding-top: 0;
}
.review-list .review-box:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.review-list .review-box .review-head {
  display: flex;
  align-items: center;
  gap: 8px;
}
.review-list .review-box .review-head .profile-pic {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  object-fit: cover;
}
.review-list .review-box .review-head .content {
  width: calc(100% - 45px - 8px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.review-list .review-box .review-head .content h5 {
  font-weight: 600;
  color: rgba(var(--dark-text), 1);
}
.review-list .review-box .review-head .content h6 {
  color: rgba(var(--light-text), 1);
}
.review-list .review-box .review-head .content .rating-star {
  display: flex;
  gap: 5px;
  color: rgba(var(--dark-text), 1);
}
.review-list .review-box .review-head .content .rating-star .star {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: rgba(var(--success-color), 1);
  border-radius: 100%;
}
.review-list .review-box .review-head .content .rating-star .star i {
  color: rgb(255, 255, 255);
}
.review-list .review-box p {
  margin-top: 10px;
  font-weight: 400;
  color: rgba(var(--dark-text), 1);
}

.tag-part .code-form-control {
  position: relative;
  padding: 15px calc(60px + 30 * (100vw - 320px) / 1600) 15px 15px;
}
.tag-part .apply-btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  margin-top: 0px;
  font-size: 14px;
  padding: calc(8px + 2 * (100vw - 320px) / 1600) calc(10px + 15 * (100vw - 320px) / 1600);
  border-radius: 4px;
}
.tag-part .tag-list {
  margin-top: 8px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.tag-part .tag-list li {
  height: 25px;
  padding: 6px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  background-color: rgba(var(--white), 1);
  border: 1px solid rgba(var(--theme-color), 1);
  border-radius: 15px;
  backdrop-filter: blur(2px);
  transition: all 0.8s ease-in-out;
}
.tag-part .tag-list li h5 {
  font-size: 12px;
  font-weight: 400;
  color: rgba(var(--theme-color), 1);
}
.tag-part .tag-list li .icon {
  line-height: 1;
  color: rgba(var(--theme-color), 1);
}

.variation-part {
  padding-top: 25px;
  border-top: 1px dashed rgba(var(--dashed-line), 1);
}
.variation-part .variation-box {
  margin-top: 10px;
  padding: 15px;
  background-color: rgba(var(--white), 1);
  border: 1.526px solid rgba(var(--dashed-line), 1);
  border-radius: 12px;
  box-shadow: 0px 3.051px 18.309px 0px rgba(var(--black), 0.04);
}
.variation-part .variation-box .form-check {
  margin-bottom: 0;
}
.variation-part .variation-box .form-check .form-check-input {
  width: 20px;
  height: 20px;
  background-color: rgba(var(--white), 1);
  border: 1px solid rgba(var(--dashed-line), 1);
}
.variation-part .variation-box .form-check .form-check-input:focus {
  box-shadow: none;
  border-color: none;
}
.variation-part .variation-box .form-check .form-check-label {
  color: rgba(var(--light-text), 1);
  font-size: 16px;
  margin-left: 10px;
}
[dir=rtl] .variation-part .variation-box .form-check .form-check-label {
  margin-right: 10px;
  margin-left: unset;
}
.variation-part .variation-box .form-check .form-check-input:checked {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(var(--theme-color), 1);
}
.variation-part .variation-box .form-check .form-check-input:checked::after {
  content: "";
  height: 10px;
  width: 10px;
  background-color: rgba(var(--theme-color), 1);
  border-radius: 100%;
}
.variation-part .variation-box .box-content {
  width: 50%;
  display: flex;
  justify-content: flex-start;
}
.variation-part .variation-box .box-content .form-check-reverse .form-check-input {
  border: 1px solid rgba(var(--dashed-line), 1);
  box-shadow: none;
  padding-right: 0;
}
[dir=rtl] .variation-part .variation-box .box-content .form-check-reverse .form-check-input {
  padding-right: unset;
  padding-left: 0;
}
.variation-part .variation-box .box-content .form-check-reverse .form-check-input:checked {
  background-color: rgba(var(--theme-color), 1);
}

.timing-part {
  margin-top: 20px;
  border-top: 1px dashed rgba(var(--dashed-line), 1);
}

.restaurant-order-tab {
  flex-wrap: nowrap;
  width: 100%;
  margin: 30px auto 0;
  border-radius: 6px;
  overflow: scroll;
  gap: 20px;
  display: flex;
}

.shipping-details {
  margin-top: 15px;
  padding: 15px;
  background-color: rgba(var(--white, 1));
  border: 1px solid rgba(var(--dashed-line), 1);
  border-radius: 12px;
  box-shadow: 0px 3px 18px 0px rgba(var(--black), 0.04);
}
.shipping-details .title-content {
  font-weight: 600;
  color: rgba(var(--dark-text), 1);
}
.shipping-details .shipping-content {
  margin-top: 10px;
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(var(--dashed-line), 1);
  border-radius: 6px;
}
.shipping-details .shipping-content .icon {
  width: 20px;
  height: 20px;
  filter: invert(0.3);
}
.shipping-details .shipping-content .icon {
  width: 20px;
  height: 20px;
  filter: invert(1) grayscale(1);
}
.shipping-details .shipping-content h6 {
  padding-left: 10px;
  border-left: 1px solid rgba(var(--dashed-line), 1);
}
@media (max-width: 600px) {
  .shipping-details .shipping-content h6 {
    padding-left: calc(5px + 5 * (100vw - 320px) / 1600);
  }
}
[dir=rtl] .shipping-details .shipping-content h6 {
  padding-left: unset;
  padding-right: 10px;
  border-left: unset;
  border-right: 1px solid rgba(var(--dashed-line), 1);
}

.notify-part {
  margin-top: 25px;
  padding: 12px;
  background-color: rgba(var(--white, 1));
  border: 1px solid rgba(var(--dashed-line), 1);
  border-radius: 12px;
  box-shadow: 0px 3px 18px 0px rgba(var(--black), 0.04);
}
.notify-part .form-check .form-check-label {
  font-weight: 600;
  font-size: 14px;
  color: rgba(var(--dark-text), 1);
}
.notify-part .form-check .form-check-input {
  border: 1px solid rgba(var(--dashed-line), 1);
  box-shadow: none;
  padding-right: 0;
}
[dir=rtl] .notify-part .form-check .form-check-input {
  padding-right: unset;
  padding-left: 0;
}
.notify-part .form-check .form-check-input:checked {
  background-color: rgba(var(--theme-color), 1);
}

.order-item-table {
  margin-top: 25px;
  padding: 15px;
  background-color: rgba(var(--white, 1));
  border: 1px solid rgba(var(--dashed-line), 1);
  border-radius: 12px;
  box-shadow: 0px 3px 18px 0px rgba(var(--black), 0.04);
}
.order-item-table thead {
  border-bottom: 1px solid rgba(var(--dashed-line), 1);
}
.order-item-table thead tr th {
  font-size: 14px;
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
}
.order-item-table tbody {
  border-bottom: 1px solid rgba(var(--dashed-line), 1);
}
.order-item-table tbody tr td {
  font-size: 13px;
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
}
.order-item-table tbody tr td.sub-total {
  font-size: 14px;
}

/* themes */
/*=====================
    Dark CSS start
==========================*/
body.dark .navbar-menu {
  border-top: 1px solid rgba(var(--light-text), 0.2);
}
body.dark .location .location-img {
  filter: invert(0.85);
}
body.dark .categories .food-categories {
  background: linear-gradient(149.22deg, #3c3c3c -9.22%, #2a2a2a 114.95%);
}
body.dark .products .product-box {
  background: linear-gradient(149.22deg, #3c3c3c -9.22%, #2a2a2a 114.95%);
  border: none;
  margin: 1px;
  z-index: 0;
}
body.dark .products .product-box::after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 1px;
  background: linear-gradient(161.57deg, rgba(255, 255, 255, 0.2) -44.12%, rgba(255, 255, 255, 0) 99.26%);
  -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(var(--white), 1))) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(var(--white), 1)));
  -webkit-mask: linear-gradient(rgba(var(--white), 1) 0 0) content-box, linear-gradient(rgba(var(--white), 1) 0 0);
  -webkit-mask-composite: xor;
  z-index: -1;
}
body.dark .option-accordion .accordion-item .accordion-header .accordion-button::after {
  filter: contrast(0);
}
body.dark .option-accordion .accordion-item .currency .currency-listing li {
  color: rgba(var(--dark-text), 1);
}
body.dark .brands-logo .food-brands {
  border: 1px solid transparent;
  position: relative;
  border-radius: 100%;
}
body.dark .brands-logo .food-brands::after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 100%;
  padding: 1px;
  background: linear-gradient(161.57deg, rgba(255, 255, 255, 0.2) -44.12%, rgba(255, 255, 255, 0) 99.26%);
  -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(var(--white), 1))) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(var(--white), 1)));
  -webkit-mask: linear-gradient(rgba(var(--white), 1) 0 0) content-box, linear-gradient(rgba(var(--white), 1) 0 0);
  -webkit-mask-composite: xor;
}
body.dark .search-filter .footer-modal {
  border: 1px solid rgba(var(--light-text), 0.5);
}
body.dark .btn-close {
  filter: invert(1);
}
body.dark .form-check-input {
  background-color: transparent;
  border: 1px solid rgba(var(--light-text), 1);
}
body.dark .form-control {
  background-color: rgba(var(--box-bg), 1);
  color: rgba(var(--light-text), 1);
}
body.dark .bill-details .total-detail .dots-design {
  filter: invert(0.85);
}
body.dark .address-list li .address-icon {
  border: 1px solid rgba(var(--light-text), 1);
  background: linear-gradient(149.22deg, #3c3c3c -9.22%, #2a2a2a 114.95%);
}
body.dark .address-list li .address-content {
  background: linear-gradient(149.22deg, #3c3c3c -9.22%, #2a2a2a 114.95%);
}
body.dark .modal .modal-dialog .modal-content {
  background-color: rgba(var(--white), 1);
}
body.dark .order-tracking {
  background-color: rgba(var(--box-bg), 1);
}
body.dark .coupon-box .coupon-left {
  filter: invert(0.85);
}
body.dark .coupon-box .coupon-right {
  filter: invert(0.85);
}
body.dark .voucher-box .voucher-discount .top-vector {
  filter: invert(0.85);
}
body.dark .voucher-box::after {
  box-shadow: none;
}
body.dark .voucher-box::before {
  box-shadow: none;
}
body.dark .payment-list .payment-card-box .payment-detail .add-img .img1 {
  width: 35px;
  height: 30px;
  filter: invert(1);
}
body.dark .confirm-title .for-dark {
  display: block;
}
body.dark .confirm-title .for-light {
  display: none;
}
body.dark .profile-list li .profile-box {
  background: linear-gradient(149.22deg, #3c3c3c -9.22%, #2a2a2a 114.95%);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
}
body.dark .profile-list li .profile-box .profile-icon {
  background: linear-gradient(149.22deg, #3c3c3c -9.22%, #2a2a2a 114.95%);
}
body.dark .profile-list li .profile-box .profile-icon .icon {
  filter: invert(1);
}
body.dark .otp-form .form-input .form-control {
  background: linear-gradient(149.22deg, #3c3c3c -9.22%, #2a2a2a 114.95%);
}
body.dark .search-section .form-group .filter-button {
  background: linear-gradient(149.22deg, #3c3c3c -9.22%, #2a2a2a 114.95%);
}
body.dark .auth-form .form-group .form-input .form-control {
  background: linear-gradient(149.22deg, #3c3c3c -9.22%, #2a2a2a 114.95%);
}
body.dark .auth-form .form-group .dropdown .dropdown-menu li .dropdown-item {
  color: #fff;
}
body.dark .dark-border-gradient {
  border: 1px solid transparent;
  position: relative;
  padding: 1px;
  border-radius: 6px;
}
body.dark .dark-border-gradient .form-control,
body.dark .dark-border-gradient .dropdown-toggle {
  background: linear-gradient(149.22deg, #3c3c3c -9.22%, #2a2a2a 114.95%);
  z-index: 1;
  position: relative;
}
body.dark .dark-border-gradient::after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 6px;
  padding: 1px;
  background: linear-gradient(161.57deg, rgba(255, 255, 255, 0.53) -44.12%, rgba(255, 255, 255, 0) 99.26%);
  -webkit-mask: linear-gradient(rgba(var(--white), 1) 0 0) content-box, linear-gradient(rgba(var(--white), 1) 0 0);
  -webkit-mask-composite: xor;
}
body.dark .vertical-product-box {
  background: linear-gradient(149.22deg, #3c3c3c -9.22%, #2a2a2a 114.95%);
  border: 1px solid rgba(245, 245, 245, 0.06);
}