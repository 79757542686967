/*=========================
    Category CSS start
==========================*/
.categories {
  .food-categories {
    position: relative;
    width: 80%;
    height: 80%;
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    margin: 0 auto;
    border-radius: 8px;
    z-index: 1;
    background: linear-gradient(360deg, #f5f5f5 53.51%, #f5f5f51c 100%);
    &.restaurant-food-categories {
      background: rgba(var(--white), 1) !important;
    }

    @media (max-width: 420px) {
      height: 100%;
      width: 100%;
    }

    &.food {
      background: rgba(var(--white), 1);
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
      border-radius: 8px;
    }

    &::after {
      content: "";
      position: absolute;
      width: 98%;
      height: 100%;
      border-radius: 9px;
      left: 50%;
      bottom: 2px;
      z-index: -1;
      transform: translateX(-50%);
    }

    .categories-img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 50px;
      object-fit: contain;

      @media (max-width: 420px) {
        width: 100%;
        height: 40px;
      }
    }
  }

  h6 {
    color: rgba(var(--dark-text), 1);
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
